.syncedTextArea
{
    width: 100%;
    border: none;
    scrollbar-color: auto transparent !important;

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &:focus
    {
        min-height: unset !important;
    }
}
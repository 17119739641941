.sku-keywords-container {
    button.bulk-keywords-toggler {
        background: none;
        border:none;
        padding:0px;
        margin:0px;
        margin-right: 20px;
        width:20px;
        height:20px;
        outline: none;
        box-shadow: none;
        -webkit-outline: none;
        background:url('../../../media/KeywordsIcons/expand.svg');
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
    }
    button.bulk-keywords-toggler.expanded {
        background:url('../../../media/KeywordsIcons/collapse.svg');
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .error-text {
        color: red;
        font-size: 10px;
        line-height: 10px;
        padding-top: 5px;
    }
    .error-text::before {
        content:"* "
    }
    .action-button-container {
        margin-top: 10px;
    }
    .error-message {
        text-align: right;
        font-size: 11px;
        color: red;
        b {
            font-weight: 700!important;
        }
    }
    
    .sku-title {
        margin-left: 30px;

        &-container {
            width: 100%;
            display: flex;
            align-items: center;
        }

        &-control {
            margin-left: auto;
        }

        &-desc {
            margin-bottom: 0px; 
            margin-left: 30px; 
            margin-top: 20px;
        }
    }
    
    .loader-container {
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


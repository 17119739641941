.registrationProgress .progress-bar
{
    background-color:   #4FDA03 !important;
    font-size: 1rem;
    font-weight: 700;
}

.registrationFormBody
{
    padding: 0 10px 10px;
}
.container-with-padding {
    padding: 40px;
}
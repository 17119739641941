.publicNav
{
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 505;
    height: 52px;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: 12px;

    > img 
    {
        height: 40px;
        padding-left: 8px;
    }
}

.imagereport-wrapper
{
    padding: 1.5rem 3%;

    .imagereport-headerrow
    {
        display: flex;
        padding-top: 20px;

        .imagereport-header
        {
            .h1-simple
            {
                margin-top: 0px !important;
            }
        }

        .header-button-area
        {
            margin-left: auto;
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 1168px) {
                flex-direction: column-reverse;
                justify-content: flex-end;
                align-items: flex-end;
            }

            .header-button {
                margin-left: 10px;
                word-wrap: nowrap;

                @media screen and (max-width: 1168px) {
                    &:first-child {
                        margin-top: 5px;
                    }
                }
            }
        }


    }

    .rootDiv
    {
        display: flex;
        flex-wrap: wrap;
        
        .imagePreview
        {
            .imagePreviewImg
            {
                padding: 10px; 
                //height: 90%; 
                background-color: #efefef;
            }
        }

        > div
        {
            margin-bottom: 20px;
        }

        > div ~ div
        {
            margin-left: 20px;
        }
    }

    .loadingCircle
    {
        width: 100%;
        margin: 10% 0%;
        text-align: center;
    }
}
@import url('https://fonts.googleapis.com/css?family=Caveat:700&display=swap');
@import '../../assets/scss/variables.scss';


.loading-screen-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-screen-form {
        height: 220px;
        width: 320px;
        background-color: white;
        border-radius: 5px;
        padding: 30px 20px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .logo {
            max-height: 40px;
            height: 100%;
            margin-bottom: 20px;
        }
    }
}

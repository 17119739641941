.csui_switch.switch 
{
    height: 20px;
    line-height: 15px;

    > label
    {
        height: 20px;
    }

    .lever
    {
        width: 60px;
        border: solid 1px #c0c0c0;
        font-size: 10px;
        color: #696969;
        text-align: right;
        font-weight: 300;
        position: relative;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 10px;
        display: inline-block;
        background: #FFF;
        height: 20px;
        cursor: pointer;
    }
    &.small .lever {
        width: 35px;
        height: 16px;
    }
    input[value="true"] + .lever 
    {
        background: #FFF!important;
        border-color: indigo !important;
    }
    &.dark-switch input[value="true"] + .lever, &.green-switch input[value="true"] + .lever {
        border-color: #c0c0c0 !important;
    }
    

    .lever::after 
    {
        position: absolute;
        top: 2px;
        width: 22px;
        border-radius: 10px;
        background: #a6a6a6;
        content:"";
        display: inline-block;
        height:14px ;
        box-shadow: none;
    }
    &.small .lever::after {
        height: 10px;
        width: 14px;
    }

    input:not([value="true"]) + .lever::after 
    {
        left: 2px;
    }

    input[value="true"] + .lever::after 
    {
        background-color: indigo !important;
        left:34px !important;
    }
    &.small input[value="true"] + .lever::after {
        left:17px !important;
    }
    &.dark-switch input[value="true"] + .lever::after {
        background-color: #696969 !important;
    }
    &.green-switch input[value="true"] + .lever::after {
        background-color: #4FDA03 !important;
    }
    &.csui_switch_green input[value="false"] + .lever::after {
        background-color: #FFF !important;
        border: solid 1px #696969;
    }
    &.csui_switch_green input[value="true"] + .lever::after {
        background-color: #4FDA03 !important;
    }

    input:not([value="true"]) + .lever::before 
    {
        position: absolute;
        left: 30px;
        top: 3px;
        content:"OFF"
    }

    input[value="true"] + .lever::before 
    {
        position: absolute;
        right: 35px;
        top: 3px;
        content:"ON"
    }

    
    input[value="true"] + .lever::before,
    input:not([value="true"]) + .lever::before 
    {
        height: 20px;
    }
    
    &.csui_switch_green input[value="true"] + .lever, &.csui_switch_green input[value="false"] + .lever {
        border-color: #696969!important;
    }
}


.delete-job-confirmation-dialog 
{
    z-index: 999;
    position: fixed;
    background: #FFF;
    box-shadow: 0px 2px 10px #999;
    padding: 30px;
    border-radius: 3px;
    top: 30%;
    left: 30%;
    width: 40%;
    font-size: 22px;
    color: #404040;
    text-align: center;

    p 
    {
        font-weight: 300;
        font-size: 16px;
    }

    h1 
    {
        color: #404040;
        font-size: 20px;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }

    .options 
    {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        button 
        {
            flex-grow: 1;
            margin:10px;
            background: #FFF;
            border-radius: 10px;
            box-shadow: 0px 2px 10px #666;
            padding: 20px 15px;
            color: #404040;
            font-size: 16px;
            text-align: center;
            font-weight: 300;

            b 
            {
                font-weight: 700;
            }
        }

        button.large 
        {
            padding: 20px 15px;

            b 
            {
                font-size: 30px;
                font-weight: 300;
            }
        }

        button:hover 
        {
            box-shadow: 0px 2px 15px #999;
        }
    }
}
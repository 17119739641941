.syncedDivTest
{
    > div
    {
        
        
    }

    .cardTitle
    {
        display: flex;
        align-items: center;
        margin: -10px;
        padding: 10px;

        .text
        {

            
            display: flex;
            align-items: center;

            > span
            {
                font-size: 2rem;
                margin-right: 20px;
                color: indigo;
                width: 48px;
                text-align: right;
            }
        }

        .icons
        {
            margin-left: auto;
        }

        &.minimizedExpander
        {

            &:hover
            {
                cursor: pointer;
            }
        }
    }

    .cardExpandedContent
    {
        display: flex;
        flex-direction: column;

        margin-top: 10px;
    }
}
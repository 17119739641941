.productScoreBar
{

	&.bar-wrap {
		
		width: 210px;
		margin: 0 auto;
		padding-top: 10px;
	}

	.score-bar {
		width: 100%;
		display: flex;
		position: relative;
		z-index: 3;
	}
	
	.score-wrapper {
		width: 100%;
		position: relative;
	
		.triangle-wrap, 
		.triangle-up 
		{
			transition: all 0.4s ease;
		}
		
		.triangle-wrap 
		{
			position: absolute;
			top: -6px;
			z-index: 2;
			left: 0;
			width: 26px;
			height: 10px;

			.val 
			{
				position: absolute;
				color: indigo;
				top: -18px;
				left: -20px;
				width: 40px;
				font-weight: bold;
				font-size: .8rem;
				text-align: center;
			}

			.triangle-up 
			{
				position: absolute;
				//top: -2px;
				left: -1px;
				width: 2px;
				height: 6px;
				background-color: black;

				span 
				{
					position: absolute;
					top: -18px;
					font-size: 12px;
					left: -2px;
					color: #6f6f6f;
					font-weight: bold;
				}
			}
		}
	}

	.bar
	{
		width: 210px;
		max-width: 300px;

		> div {
			margin: 0;
			padding: 0;
			height: 22px;
			width: 20%;
			display: inline-block;
			position: relative;
		}

		> div:first-child {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}

		> div:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}

		.segment {
			position: absolute;
			/* left: 5px; */
			text-align: center;
			bottom: -15px;
			font-size: 10px;
			color: #6f6f6f;
			font-weight: bold;
			width: 100%;
		}

		.step {
			color: #fff;
			font-weight: bold;
			bottom: 0px;
			position: absolute;
			left: 3px;
			font-size: .8rem;
		}

		.fail {
			background: #4d4d4d;
		}

		.neutral {
			background: #7f7f7f;
		}

		.good {
			background: #d9d9d9;

			.step
			{
				color: rgb(63, 63, 63);
			}
		}

		.excellent {
			background: indigo;
		}
	}

	.upper-number-wrap {
		position: relative;
	}	
	
}

.productScoreBarBinary
{
	
	.bar
	{
		

		.step {
			font-weight: 500;
			bottom: unset;
			position: relative;
			left: unset;
			font-size: .8rem;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		> div {
			width: 50%;
		}

		.noneHalf {
			background: #4d4d4d;
		}
	
		.pointsHalf {
			background: indigo;
		}

		.active
		{
			.step
			{
				color: white;
			}
		}

		.inactive
		{
			background: white;
			border: 1px solid rgb(63, 63, 63);

			.step
			{
				color: rgb(110, 110, 110);
			}
		}

	}
}
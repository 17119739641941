.csui_select.md-form
{
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid lightgrey !important;
    border-radius: 3px;
    padding: 0 4px !important;

    .dropdown-content li:not(.disabled) > span
    {
        color: rgb(46, 46, 46);
    }
    
    input
    {
        font-family: 'Montserrat';
    }

    ul.select-dropdown 
    {

        top: -1px !important;
        left: -1px;
        width: 102%;
    
    } 

    label
    {
        margin: 0;
        margin-right: 8px;
        position: relative;
        top: unset !important;
        left: 4px !important;
        transition: unset;
        transform: unset !important;
        transform-origin: unset;

        &.active
        {
            visibility: hidden;
        }
    }

    span.caret
    {
        position: relative;
        top: unset;
        right: unset;
        margin-left: auto;
    }

    input.select-dropdown 
    {
        top: unset !important;
        margin: 0;
        height: 100%;
        border: none !important;
        position: absolute;
        left: 4px;
        line-height: 1rem;
    }
}
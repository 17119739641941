@import '../../../../assets/scss/variables.scss';

.cs-progress-card {
    border:solid 1px #d8d8d8;
    background:#FFF;
    border-radius: 5px;
    flex-grow: 1;
    margin:5px;
    padding: 5px;
    min-width: 120px;
    max-height: 80px;
    height: 100%;
}
 .flexible {
    max-height: none;
    min-height: 81px!important;
}

.cs-progress-card .grid-card-layout {
    padding: 0 10px;
    width: 100%;
    text-align: center!important;
}

.cs-progress-card .grid-card-layout .separator {
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
    margin-bottom: 5px;
}

.cs-progress-card .grid-card-layout .flagged-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    div {
        width: 100%;
    }
}

.cs-progress-card img {
    width: 24px;
    height: 24px;
}

.cs-progress-card .small-img {
    width: 21px!important;
    height: 21px!important;
}
.cs-progress-card h1
{
    margin: 0px!important;
    padding: 0px!important;
    text-align: center;
    // padding-right: 20px!important;
    font-size: 11px!important;
    text-transform: uppercase;
    color: #a5a5a5;
    background-color: #fff;
    word-wrap: nowrap!important;
}
.cs-progress-card h1 span {
    font-weight: 300;
}
.cs-progress-card h1 span.new-line {
    display: block!important;
    font-size: 12px!important;
}
.cs-progress-card h2 {
    font-size: 26px;
    font-weight: 900!important;
    margin: 0px!important;
    padding: 0px!important;
    text-align: center;
    // padding-right: 20px!important;
    text-transform: uppercase;
}
.cs-progress-card .variation {
    font-size: 8px;
    font-weight: 700;
    margin:0px;
    line-height: 10px;
    text-align: center;
}
.cs-progress-card .variation:not(.neutral-value) {
    color:#4FDA03;

}

.cs-progress-card .variation:not(.neutral-value) span::before {
    content:"+"
}
.cs-progress-card .variation.negative span {
    color:red
}
.cs-progress-card .variation.negative span::before {
    content:""
}
// .cs-progress-card .bottomNote {
//     font-size: 6px;
//     font-weight: 500;
//     min-height: 10px;
//     line-height: 8px!important;
// }

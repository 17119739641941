.setCreditInputContainer
{
    min-width: unset !important;
}

.planSelectDiv
{
    display: flex;
    align-items: center;

    .selectPlanDropdownDiv
    {
        margin-right: 40px;
    }
}
.csui_table
{
    font-size: 14px !important;

    th > div,
    td > div
    {
        font-size: 14px !important;
    }

    th.MuiTableCell-root, td.MuiTableCell-root
    {
        padding: 7px;
    }
}

.csui_mui_xgrid
{
    .MuiDataGrid-main
    {
        > :nth-child(2) ~ div
        {
            visibility: hidden;
        }
    }

    &.csui_default_style
    {
        &.MuiDataGrid-root {
            min-height: 200px !important;
            /* overflow-y: hidden; */
        }
    }
}
.adminSegment
{
    padding: 3em;

    .adminHeader
    {
        font-size: 2rem;
    }

    .CSUITabsContent-Active
    {
        min-height: 400px;
    }

    .adminTabs
    {
        .mt-5, .my-5 
        {
            margin-top: 0!important;
        }
    }
} 
.MuiFormControlLabel-root {
    margin-left: unset !important;
    margin-bottom: unset !important;

    span.MuiCheckbox-root {
        padding: 4px;
        margin-right: 12px;
    }
}

.form-check input[type="checkbox"].form-check-input.csui_checkbox.csui_purple:checked + label::after {
    background-color: indigo !important;
    border-color: indigo !important;
}

@import '../../../../assets/scss/variables.scss';

.star-rating {
    font-size: 10px;
    color: $text_color;
    padding-top: 10px;
}
.star-rating div.star {
    display: inline-block;
}
.star-rating .star.full {
    background:url('../../../../media/CatalogProductIcons/star.png');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: bottom;
}
.star-rating div.star.half {
    background:url('../../../../media/CatalogProductIcons/half-star.png');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: bottom;
}
.star-rating div.star.empty {
    background:url('../../../../media/CatalogProductIcons/empty-star.png');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: bottom;
}
.star-rating div.star {
    width:16px;
    height: 16px;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    
    
}
.star-rating div.star:last-child {
    margin-right: 10px;
}

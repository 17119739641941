#betalock .view {
  // background-image: url('http://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img%20(11).jpg');
  // background-size: cover;
  height: 100vh;
}

#betalock .modal-header {
  margin: auto;
}

#betalock .modal-header img {
  width: 100%;
}

.motd-alert
{
  color: red;
  font-size: smaller;
}

.beta-password-form
{
  margin-bottom: 30px;
}

.beta-password-form .form-group
{
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
}

.beta-password-form i.beta-password-icon
{
  position: relative !important;
  font-size: 1.5rem;
  margin-top: 8px;
  margin-left: 8px;
}

.beta-password-form .invalid-feedback {
  position: absolute;
  top: 35px;
}

#beta-password
{
  margin: 0;
}

input#beta-password
{
  width: 100%;
}
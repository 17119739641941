.CSUITabs
{
    .nav-tabs
    {
        .tabs-grey-text {
            background-color: #fff;
            border-bottom: 1px solid #dee2e6;

            &-active {
                border-bottom: 1px solid #fff;

                .nav-link {
                    color: #5f5f5f!important;
                    border-top: 2px solid #cfcfcf!important;
                }
            }
            a {
                color: #5f5f5f!important;
            }
        }

        .nav-item
        {

            .nav-link.CSUITabsHeader
            {

                font-family: 'Montserrat', Lato,'Helvetica Neue', Arial, Helvetica, sans-serif !important;
                font-size: 1rem;
                padding: 0 1.42857143em;
                height: 34px;
                min-width: 152px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:not(.CSUITabsHeader-Active) 
                {
                    color: inherit;
                    background-color: transparent;
                    border-color: transparent;
                    border-color: #dee2e6 #dee2e6 transparent transparent;
                    border-bottom-color: transparent;
                }

                &.CSUITabsHeader-Active 
                {
                    color: indigo;
                    font-weight: bold;
                    border-top: 2px solid indigo;
                }

                &.CSUITabsHeader-Active 
                {
                    color: indigo;
                    font-weight: bold;
                    border-top: 2px solid indigo;
                }
                
            }

            &:first-child
            {
                .nav-link.CSUITabsHeader
                {
                    border-left-color: #dee2e6;
                }
            }
        }
    }

    .CSUITabsContent-Active
    {
        border-left: solid 1px #dee2e6;
        border-bottom: solid 1px #dee2e6;
        border-right: solid 1px #dee2e6;
        padding: 1em;
    }
}

.white-bg {
    background-color: #fff;
}
@import '../../../../assets/scss/variables.scss';
.image-box-container {
    margin-right: 20px;
    margin-bottom: 10px;
    .image-container {
        background: #ffffff;
        border-radius: 5px;
        border: solid 1px #CCC;
        width: 72px;
        height: 72px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            max-width: 70px;
            max-height: 70px;
            border-radius: 5px;
        }
    }
    .dimensions {
        font-size: $text-color;
        font-size: 10px;
        text-align: center;
        &.with-errors {
            color: red;
        }
    }
}
@import '../../../../assets/scss/variables.scss';

.catalog-product-container .cards-line {
    display: flex;
    flex-direction: row;
    max-height: 100px;   
}
.catalog-product-container .cards-line:not(:last-child) {
    margin-bottom: 20px;
}

.catalog-product-container .cards-line
.cs-progress-card:nth-child(1) {
    margin-left: 0px;
}

.catalog-product-container .cards-line.tight 
.cs-progress-card:nth-child(1) {
    margin-left: 0px!important;
}

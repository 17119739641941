@import '../../../../assets/scss/variables.scss';

.card {
    border-radius: .25em;
}

.keywords-table {
    // font-size: 14px;
    margin-bottom: 10px;

    .table-head 
    {
        div 
        {
            background: #f2f2f2 !important;
            // font-weight: 500;
        }
    }

    div.warning 
    {
        position: relative;

        &::before 
        {
            background-image: url('../../../../media/CatalogProductIcons/warning.svg');
            background-size: 8px 8px;
            background-repeat: no-repeat;
            width: 8px;
            height: 8px;
            content: "";
            position: absolute;
            left: -15px;
            top: 6px;
        }
    }
}

.flaggedList
{
    .flaggedSection
    {
        margin-bottom: 20px;

        > span
        {
            font-weight: 700;
            color: black;
        }

        > ul
        {
            list-style: none;
            padding-left: 20px;

            li
            {
                div
                {
                    display: inline-flex;
                    align-items: center;
                    margin-left: -14px;

                    &.isError
                    {

                        &::before 
                        {
                            background-image: url('../../../../media/CatalogProductIcons/warning.svg');
                            background-size: 8px 8px;
                            background-repeat: no-repeat;
                        }
                    }
                    

                    &::before 
                    {
                        width: 8px;
                        height: 8px;
                        content: "";
                        margin-right: 6px;
                    }
                }
                
            }
        }
    }
}
.teamMemberTable
{
    margin-bottom: 40px;

    .teamTableCloseX
    {
        color: red;
        width: 100%;
        height: 100%;
    }

    .teamTableCloseX:hover
    {
        color: rgb(150,0,0);
        cursor: pointer;
    }

    .tableTitle
    {
        text-align: left;
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 10px;
    }

    .pendingStatusLabel
    {
        margin-left: 12px;
    }

}

.addUsersTable
{
    border: 1px solid rgba(0,0,0,0.1);
}

.teamMemberTable.no-border .rt-table .rt-th,.teamMemberTable.no-border .rt-table .rt-td {
    border: none!important;
}

.no-border-tables .rt-table .rt-th,.no-border-tables .rt-table .rt-td {
    border: none!important;
}

.accountListTable
{

    .vertically-aligned .rt-td
    {
        height: unset;
    }

    .accountUserNames
    {
        display: flex;
        flex-wrap: wrap;
        overflow: visible;
    
        > div ~ div
        {
            margin-left: 5px;
        }
    }

    .teamIdLink
    {
        &:hover
        {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

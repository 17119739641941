.darkContainer {
    background: #444444 !important;
        border: 1px solid lightgrey !important;
        margin-top: 20px;
        height: auto !important;
        position: relative;
}

.progressCards {
    border: 1px solid!important;
}
.dual {
    margin-bottom: -18px;
}

.white-bg {
    background-color: #fff;
}
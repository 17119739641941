@import '../../../../assets/scss/variables.scss';

.score-display h3 {
    display: block;
    margin: 0px;
    padding: 0px;
    font-size: 10px;
    font-weight: 300;
}
.score-display h1 {
    margin: 0px;
    padding: 0px;
    font-size: 26px;
    font-weight: 900!important;
}
.score-display h2 {
    margin: 0px;
    padding: 0px;
    font-size: 9.5px;
    font-weight: 700!important;
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');



body {
  margin: 0;
  padding: 0;
  color: #666666;
  background-color: rgb(245, 245, 245);
  /*height: unset;*/
}

.displayNone
{
  display: none !important;
}

.purple-text {
  color: indigo !important;
}

.App > div {
  padding: 0 3%;
  width: 100%;
}

.App > div > div {
  /* max-width: 1950px; */
  margin: 0 auto;
  width: 100%;
}

body,
.ui.header, .ui.menu,
.ui.list > .item .header,
.ui.items > .item > .content > .header,
h1, h2, h3, h4, h5
{
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}
h2.ui.header {
  color: #404040;
}
html {
  font-size: 75%;
}

.ui.checkbox input:checked ~ .box::after, .ui.checkbox input:checked ~ label::after,
.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before,
.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before {

  background-color: indigo /* #4FDA03  */ !important; /*rgb(255,192,0) !important;*/

}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
}

.csGreen,
.newjobReport.chromelessButton.ui.basic.button {
  color: indigo /* #4FDA03  */!important;
}

.chromelessButton.ui.basic.button {
  padding: 0 !important;
  box-shadow: none !important;
}

.chromelessButton.ui.basic.button:hover,
.chromelessButton.ui.basic.button:active,
.chromelessButton.ui.basic.button:focus {
  padding: 0 !important;
  box-shadow: none !important;
}

.chromelessButton.ui.basic.button:active {
  background: darkgray !important;
}

.newjobAddContact.ui.basic.button:hover,
.newjobAddContact.ui.basic.button:active {
  color: #007900 !important;
}

.unselectRemove {
  color: red;
  cursor: pointer;
  font-weight: bold;
  margin-right: 5px;
}

.unselectRemove:hover,
.unselectRemove:active,
.unselect.ui.basic.button:hover,
.unselect.ui.basic.button:active {
  color: #811515 !important;
}

.stickyColumnTitle {
  position: -webkit-sticky;
  position: sticky;
  padding: 0 10px;
  left: 0; /* <-- become sticky once touching left edge */
}
 /*
.reviewStageCircleContainer {
 border-left: 1px solid lightgrey;
}
*/

.ItemCell {
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  /* border-left: none; */
  margin-left: -1px;
  display: flex;
  flex-direction: column;
  text-align: left;

}

.UID_Bottom .ItemCell {
  /* border: none; */
  margin: 0 0 0px;
  /* border-bottom: 1px solid lightgrey; */
}

.UID_Bottom > div > div {
  /* margin-left: 2px; */
  /* border-left: none !important; */
  margin-left: -1px;
}

.scrollColumn > div:first-child .flexContainerRow > div:first-child .ItemCell,
.scrollColumn > div:first-child .UID_Bottom > div:first-child > div,
.scrollColumn > div:first-child .UID_Top > div:first-child .ItemCell
{
  margin-left: 0px !important;
}

.UID_Bottom > div:first-child .AccuracyDragHandle
{
  /* border-left: 2px solid lightgrey !important; */
}

.reviewRadio .field {
  margin: 0 !important;
}

.longvalue,
.longvalue div {
  text-align: start;
  align-items: start !important;
}

.notesTextarea:focus {
  max-height: 75px !important;
  min-height: 40px !important;
  z-index: 2;
}

#customHeader {
  border-bottom: 1px solid rgba(0,0,0,.15);
}

#customHeader .ui.menu {
  font-size: 1rem;
  margin: 0 0 1px;
  border-bottom: none;
}

#customHeader .ui.secondary.pointing.menu > .active.item,
#customHeader .ui.secondary.pointing.menu > .active.item:hover {
  /* box-shadow: inset 0 -.6px #000; */
  border-color: indigo; /* #4FDA03; */
  font-weight: 900;
}

#customHeader .ui.secondary.pointing.menu > .item {
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
}

#customHeader .ui.secondary.pointing.menu > .item:hover {
  /*box-shadow: inset 0 -.6px #000;*/
  border-color: black;
}

#customHeader .ui.secondary.pointing.menu > .item > i {
  color: #4FDA03;
}

#customHeader .ui.secondary.pointing.menu > .item > .icons {
  margin-right: 7.5px !important;
}

#customHeader .ui.secondary.pointing.menu > .item > i > .corner {
  right: 0px !important;
  font-size: .55em !important;
}

#root {height: 100%}

#newjobstep2 .segment {
  height: 100%;
}

/*
.visualFieldMapper .rt-table,
.visualFieldMapper .rt-tbody {
  overflow: visible;
}

.visualFieldMapper .rt-tbody {
  overflow-y: auto;
  max-height: 500px;
}
*/

.visualFieldMapper .rt-tr:hover {
  background: rgba(51, 255, 0, 0.19) !important;
}

.visualFieldMapper .ReactTable,
.visualFieldMapperBrand .ReactTable {
  font-size: .9rem;
}

.visualFieldMapper .-center,
.visualFieldMapperBrand .-center {
  max-width: 75%;
  flex: initial !important;
}

.visualFieldMapper .-pageInfo,
.visualFieldMapperBrand .-pageInfo {
  line-height: 2.5rem;
}

.visualFieldMapper .ui.search.dropdown .menu,
.visualFieldMapperBrand .ui.search.dropdown .menu {
  max-height: unset;
}

.visualFieldMapper .ui.selection.dropdown,
.visualFieldMapperBrand .ui.selection.dropdown {
  transform: unset;
}

.accuracyButtons .compactButton.basic {
  /* padding: .5em 1.15em !important; */
}

.compactButton,
.newjob_button, .newjob_buttonClear {
  padding: .5em 1em !important;
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

.mapperDimmer.ui.inverted.dimmer {
    background-color: rgba(255,255,255,.35);
}

.mapperDimmer.ui.inverted.dimmer > .content {
  color: black;
}

.themedButton.ui.basic.green.button,
.newjob_button.ui.basic.green.button {
  box-shadow: 0 0 0 1px #4FDA03 inset !important;
  color: rgb(110, 110, 110) !important;
}

.stepContent .input > input {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 24px;
}

.themedProgress.ui.indicating.progress[data-percent^="100"] .bar {
  background-color: #4FDA03 !important;
}

.newjob_button,
.themedButton,
.toggleButton {
  /*background-color: rgb(220, 220, 220) !important;
  background-color: #50ff50 !important;*/
  background-color: #4FDA03 !important;
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

.newjob_button:hover,
.toggleButton:hover,
.themedButton:hover {
  /*background-color:rgb(80, 245, 80) !important;*/
  background-color: #4DCC04 !important;
}

.newjob_button:active,
.toggleButton:active,
.themedButton:active {
  /*background-color:rgb(80, 230, 80) !important;*/
  background-color: #48BF03 !important;
}

.toggleButton {
  padding: 0 !important;
  text-shadow: 0 0 1px rgb(0, 255, 0) !important;
}

#newjobstep3 .statistic > .label,
#newjobstep3 .statistic > .value,
#newjobstep2 .statistic > .label,
#newjobstep2 .statistic > .value {
  /*color: #50FF50 ; gb(255,192,0);*/
  font-weight: 900;
  text-shadow: .5px .5px 1px rgb(200,200,200);
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#newjobstep3 .statistic > .label,
#newjobstep2 .statistic > .label {
  margin-right: 10px;
}

#newjobstep4 .statistic > .label,
#newjobstep4 .statistic > .value {
  color: rgb(60,60,60);
  font-weight: 900;
  text-shadow: .5px .5px 1px rgb(200,200,200);
}

#newjobstep3 .flexContainer > div ~ div {
  margin-left: 20px;
}

#newjobstep3 .flexContainer .segment {
  height: 325px;
  width: 325px;
}

#previewtable .item {
  padding: 5px 10px;
  border: 1px solid #4FDA03; /*rgb(255,192,0);*/
  margin: 0 auto;
  font-size: 13px;
}

#previewtable .visualFieldMapper .active.item {
  background: unset;
  color: unset;
  text-shadow: unset;
}

#previewtable .visualFieldMapper .item {
  padding: unset;
  border: unset;
  margin: unset;
  font-size: unset;
}

#previewtable .active.item {
  background: none #4FDA03; /*rrgb(255,192,0);*/
  color: white;
  text-shadow: .5px .5px 5px rgb(50,50,50);
}

#previewtable .active.tab {
  border-color: #4FDA03; /*rrgb(255,192,0);*/
  border-radius: 0 0 10px 10px;
}

#previewtable {
  width: 90%;
  min-width: 865px;
  max-width: 1050px;
  margin: 0 auto;
}

#previewtable .tabular {
  flex-wrap: wrap;
  border-bottom: 1px solid #4FDA03; /*rrgb(255,192,0);*/
  display: flex;
  min-height: 0;
  justify-content: flex-start;
}

#previewtable .tabular .item:first-of-type {
  margin-left: 0;
}

#previewtable .tabular .item:last-of-type {
  margin-right: 0;
}

#csv_input {
  overflow: hidden;
}

.auth_btn div,
.auth_btn button {
  padding: 3px 8px !important;
}

.activeUser_btn i {
  color: #4FDA03 !important;
}

.socialIcons > .rating > .active.icon,
.socialIcons > .rating > .selected.icon,
.socialIcons {
  color: #4FDA03 !important;
}

.socialIcons > i {
  margin-left: 5px;
}

.socialIcons {
  margin-right: 15px;
}

.dashboard_topRow > div,
.dashboard_topRow > div > div {
  min-width: 200px;
  min-height: 200px;
}

.dashboard_addCredits {
  color: white !important;
  box-shadow: -1px 1px 4px 0 rgba(0,0,0,.65) !important;
}

.dashboard_addCredits:active {
  box-shadow: -1px 1px 4px 0 rgba(0,0,0,.35) !important;
}

.newjob_container_segment {
}

.RetailerAdminTab .ui.attached.tabular.menu,
.contentstatus_tabMenu .ui.attached.tabular.menu,
.newjob_container_segment .ui.attached.tabular.menu,
.jobStatusData_container_segment .ui.attached.tabular.menu
{
  border-top: 1px solid rgba(34,36,38,.15);
  border-right: 1px solid rgba(34,36,38,.15);
  border-left: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem .28571429rem 0 0;
  border-bottom-color: rgb(227, 227, 227);
  overflow-x: auto;
}

.RetailerAdminTab .ui.attached.tabular.menu .item,
.contentstatus_tabMenu .ui.attached.tabular.menu .item,
.newjob_container_segment .ui.attached.tabular.menu .item,
.jobStatusData_container_segment .ui.attached.tabular.menu .item
{
  border: none;
  /* border-right: 1px solid rgba(34,36,38,.15); */
  /* border-left: 1px solid rgba(34,36,38,.15); */
  /* margin-left: -1px; */
  height: 34px;
  border-color: rgb(227, 227, 227);
  padding: 0 1.42857143em;
  min-width: 152px;
  justify-content: center;
}

.RetailerAdminTab .ui.attached.tabular.menu .item:not(:first-child),
.contentstatus_tabMenu .ui.attached.tabular.menu .item:not(:first-child),
.newjob_container_segment .ui.attached.tabular.menu .item:not(:first-child),
.jobStatusData_container_segment .ui.attached.tabular.menu .item:not(:first-child)
{
  border-left: 1px solid rgba(34,36,38,.15);
}

.RetailerAdminTab .ui.attached.tabular.menu .item:last-child,
.contentstatus_tabMenu .ui.attached.tabular.menu .item:last-child,
.newjob_container_segment .ui.attached.tabular.menu .item:last-child,
.jobStatusData_container_segment .ui.attached.tabular.menu .item:last-child
{
  border-right: 1px solid rgba(34,36,38,.15);
}

.RetailerAdminTab .ui.attached.tabular.menu .active.item,
.contentstatus_tabMenu .ui.attached.tabular.menu .active.item,
.newjob_container_segment .ui.attached.tabular.menu .active.item,
.jobStatusData_container_segment .ui.attached.tabular.menu .active.item
{
  border-top: 2px solid indigo /* #4FDA03 */;
  border-right: 1px solid rgba(34,36,38,.15);
  /* border-left: 1px solid transparent; */
  /* border-bottom: 2px solid #4FDA03; */
}

.RetailerAdminTab .ui.attached.tabular.menu .active.item + .item,
.contentstatus_tabMenu .ui.attached.tabular.menu .active.item + .item,
.newjob_container_segment .ui.attached.tabular.menu .active.item + .item,
.jobStatusData_container_segment .ui.attached.tabular.menu .active.item + .item
{
  border-left: none;
}

.jobStatusData_container_segment .tabMenuItem .statusNameStyled {
  font-size: 1rem !important;
  height: 12px;
}

.jobStatusDataContainer .ui.inverted.dimmer .ui.loader::after,
.newAccuracyJob .ui.inverted.dimmer .ui.loader::after,
.newjob_container_segment .ui.inverted.dimmer .ui.loader::after
{
  border-color: #43BF00 transparent transparent !important;
}

.p {
  margin: 0 0 1em;
  line-height: 1.4285em;
}

.newjob_container_segment p a,
.newjob_container_segment .p a {
  color: #43BF00 !important;
  text-decoration: underline !important;
}

.newJobStepTitle {
  width: 75%;
  text-align: left;
  margin-bottom: 15px;
}

.newJobStepTitle > .label {
  background-color: rgb(90, 90, 90) !important;
  color: white !important;
}

.newJobStepTitle > span {
  margin-left: 10px;
  font-weight: 900;
  color: rgb(70, 70, 70);
}

.jobStatusData_container_segment {
  width: 100%;
}

/*
.jobStatusData_container_segment .tabMenuItem {
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

.jobStatusData_container_segment .tabMenuItem .title {
  font-weight: 900 !important;
}

.jobStatusData_container_segment .tabMenuItem {
  font-weight: 400 !important;
  margin-right: 10px;
  border-color: #4FDA03 !important;
  font-size: 1.2rem !important;
  padding: .3em 1.4em !important;
}

.jobStatusData_container_segment .active.tabMenuItem {
  background-color: #4FDA03 !important;
  text-shadow: 0 0 5px white;
}



.jobStatusData_container_segment .paneHeader {
  margin: -1em -1em 0;
  background-color: rgb(200,200,200) !important;
  padding: .4em;
}
*/

.newJobStepDetails > div > .ui.segment,
.AdminContainer > .ui.segment,
.jobStatusDataContainer > .ui.segment,
.dataTabContainer > .ui.segment,
.myTeamContainer > .ui.segment {
  /* border-color: #4FDA03 !important; */
}

.AdminContainer > .ui.segment {
  width: 100%;
}

.dataTabContainer > .ui.segment {
  padding: unset !important;
  margin: 10px auto !important;
  width: 98% !important;
}

.dataTabContainer {
  /* padding-top: 10px; */
}

.myTeamContainer > .ui.segment {
  width: 100% !important;
}

.newJobStepDetails > div {
  margin: 0 auto !important;
  width: 80% !important;
}

.newJobStep4_timeCheckHolder .hourInput {
  margin-left: 5px;
  margin-right: 5px;
}

.newJobStep4_timeCheckHolder .timeCheck {
  margin-left: 15px;
  width: 100px;
  display: inline-block;
}

.dashboardCredits .statistic > .label,
.dashboardCredits .statistic > .value {
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 900;
}

.dashboardCredits .statistic > .value {
  font-size: 3rem !important;
  font-weight: 900;
}

.dashboardCredits .statistic > .label {
  font-weight: 700;
  text-transform: none;
}

.NewJobContactListReactTable,
.NewJobContactListReactTable div {
  font-size: .9rem;
  border: none !important;
}

.NewJobContactListReactTable .rt-tfoot {
  box-shadow: none !important;
}

.NewJobContactListReactTable .rt-td {
  padding: 1px 0 !important;
}

.NewJobContactListReactTable .rt-td a {
  text-decoration: underline !important;
  color: rgba(0,0,0,.87) !important;
}

.NewJobContactListReactTable .rt-th {
  padding: 2px 5px !important;
}

.NewJobContactListReactTable .rt-tfoot .ui.input {
  width: 100% !important;
}

.NewJobContactListReactTable .rt-tfoot .ui.input input {
  font-size: .9rem !important;
  padding: 2px 3px !important;
}

.NewJobContactListReactTable .rt-noData {
  display: none;
}

.creditsDetailLabels {
  margin-right: 6px;
  justify-content: center;
  flex-wrap: wrap;
}

.newJob_ActionButtonGroup .themedButton.ui.green.button {
  font-weight: 700;
}

.newJob_ActionButtonGroup .themedButton.ui.button,
.addCreditsButton,
.creditsDetailLabels .ui.mini.statistic > .label,
.creditsDetailLabels .ui.mini.statistic > .value,
.dash-credits .ui.statistic > .label,
.dash-credits .ui.statistic > .value
 {
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

.dash-credits .ui.statistic > .value,
.dash-credits .ui.statistic > .label
{
  text-align: left !important;
}

.dash-credits .ui.statistic > .value {
  font-size: 1.55rem !important;
  font-weight: 900;
}

.dash-credits .ui.statistic > .label {
  font-size: 1rem !important;
  font-weight: bold;
  text-transform: none;
}

.creditsDetailLabels .ui.mini.statistic > .value {
  font-size: 1.1rem !important;
  font-weight: 900;
}

.creditsDetailLabels .ui.mini.statistic > .label {
  font-weight: 400;
  font-size: .8rem !important;
  text-transform: none;
}

.creditsDetailLabels .ui.label {
  padding: .3833em .833em;
  background-color: white;
  border: 1px solid black;
  height: 30.8667px;
}

.creditsDetailLabels .ui.label:first-child,
.saveCancel .themedButton:not(:last-child) {
  margin-right: 6px;
}

.dashboard_feedback .ui.radio.checkbox label {
  font-size: .9rem;
  line-height: .95rem;
}

.dash-row1,
.dash-col2 .dash-row2 > div:first-child
{
  /* width: 250px; */
  margin-bottom: 10px;
}

.dash-col1 {
  max-width: 250px;
  margin-right: 20px;
  width: 100%;
}

.dash-col2 {
  width: 100%;
}

i.flipped.icon, i.horizontally.flipped.icon {
  -webkit-transform: scale(-1,1);
  transform: scale(-1,1);
}

.dash-col2 .dash-row1 i.icons .icon {
  top: 46.5%;
}

.dash-col2 .dash-row1 > div {
  height: 92px;
  margin: 0px;
}

.dash-col2 .dash-row1 > div ~ div {
  margin-left: 10px;
}

.dash-col2 .dash-row1 > div > div,
.dash-col2 .dash-row1 > div > a
{
  height: 100% !important;
}

.dash-credits i.inverted.icon {
  color: #4FDA03 !important;
}

.aboveTabs {
  display: flex;
  align-items: flex-start;
  min-height: 75px
}

.aboveTabs > .jobMetaData,
.aboveTabs > .export,
.newjob_container_segment .aboveTabs > div
{
  width: 33.333%;
  margin-bottom: 20px;
}

.aboveTabs > .jobMetaData,
.newjob_container_segment .aboveTabs > div:first-child
 {
  margin-right: auto;
}

.aboveTabs > .export,
.newjob_container_segment .aboveTabs > div:last-child
{
  margin-left: auto;
}

.jobStatusData_container_segment .aboveTabs .utility
{
  display: flex;
}

.jobStatusData_container_segment .aboveTabs .utility :not(:first-child)
{
  margin-left: 6px;
}

.jobStatusData_container_segment > div > .tab
{
  min-height: 225px;
}

.jobMetaData {
  text-align: left;
}

.saveCancel {
  text-align: right;
}
.saveCancel button:not(:last-child) {
  margin-right: 8px !important;
}
.nextButton {
  margin-left: auto;
}

.stepContent .newjob_button:not(:last-child) {
  margin-right: 6px;
}

#stepContent_1 .newjob_button.disabled,
#stepContent_2 .newjob_button.disabled {
  width: 115px;
}

#stepContent_1 .comingSoonButtons .newjob_button,
#stepContent_2 .comingSoonButtons .newjob_button {
  margin-bottom: 6px;
}

#stepContent_3 {
  overflow-x: hidden;
}

#stepContent_3 textarea {
  max-height: 84px;
  width: 200px;
}

#stepContent_3 #step1status:not(:first-child) {
  margin-left: 15px;
}

#stepContent_6 .legalese {
  font-size: .95rem;
  line-height: .9rem;
}

#stepContent_6 .legalese .statusNameStyled {
  font-size: 1rem;
}

.requiredStar
{
  display: inline;
  margin: -.2em 0 0 .2em;
  color: #db2828;
}

.stepContent {
  text-align: left;
  padding: 5px;
  width: 100%;
}

.stepContent .ui.divider {
  margin: 2rem 0;
}

.statusTable.ui.table thead th {
  padding: 0 .78571429em;
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: smaller;
}

.statusTable.ui.table th:nth-child(3),
.statusTable.ui.table th:nth-child(4),
.statusTable.ui.table td:nth-child(3),
.statusTable.ui.table td:nth-child(4) {
  width: 275px;
}

.statusTable.ui.table th:nth-child(5),
.statusTable.ui.table th:nth-child(6),
.statusTable.ui.table td:nth-child(5),
.statusTable.ui.table td:nth-child(6)
 {
  text-align: center;
}

.statusTable.ui.table,
.statusTable.ui.table td
{
  border: none;
}

.describeSKUGroup, .recurEnding {
  display: flex;
}

.describeSKUGroup
{
  line-height: 1rem;
}

.describeSKUGroup > div {
  margin-top: 15px;
}

.describeSKUGroup > div > div:first-child {
  margin-bottom: 5px;
}

.describeSKUGroup > div,
.recurEnding > div
{
  text-align: center;
}

.recurEnding  .newjob_button.ui.button {
  margin: 0;
}

.describeSKUGroup > div~div,
.recurEnding > div~div {
  margin-left: 30px;
  /* width: 150px; */
}

.describeSKUGroup .ui[class*="left icon"].input > input {
  padding-right: -0 !important;
  margin-right: -3px;
}

.orBox {
  border-left: 1px solid indigo;
  border-right: 1px solid indigo;
  margin: 0 30px;
  padding: 3px 5px;
  font-size: 1.5rem;
}

.statusNameStyled {
  font-size: 1.15rem;
  color: #666666;
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

#liveStatusStatsBar
{
  display: flex;
  margin-bottom: 15px;
}

#liveStatusStatsBar .barBox
{
  border: 1px solid rgba(34,36,38,.15);
  margin: 0 auto;
  width: 19.5%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: .28571429rem;
  text-align: center;
}

#liveStatusStatsBar .barBox:first-child
{
  margin-left: 0;
}

#liveStatusStatsBar .barBox:last-child
{
  margin-right: 0;
}

#liveStatusStatsBar .barBox.mainStat .boxTop
{
  font-size: 2.5rem;
  color: #4FDA03;
}

#liveStatusStatsBar .barBox.mainStat .boxBottom
{
  margin-top: 3px;
}

#liveStatusStatsBar .barBox .boxTop
{
  color: black;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
}

#liveStatusStatsBar .barBox .boxBottom
{
  margin: 0 5px;
}

.AccuracyDragHandleTab
{
  background-color: transparent;
}

.AccuracyDragHandleTab .leftTop
{
  border-top-right-radius: 100%;border: 1px solid white;width: 6px;height: 50%;border-left: none;border-bottom: none;
}

.AccuracyDragHandleTab .leftBottom
{
  border-bottom-left-radius: 100%;border: 1px solid white;width: 12px;height: 50%;border-right: none;border-top: none;align-self: end;
  /* margin-left: -1px; */
}

.AccuracyDragHandleTab .handleContent
{
  width: 100%;border-bottom: 1px solid white;
}

.AccuracyDragHandleTab .rightTop
{
  border-top-left-radius: 100%;border: 1px solid white;width: 6px;height: 50%;border-right: none;border-bottom: none;
}

.AccuracyDragHandleTab .rightBottom
{
  border-bottom-right-radius: 100%;border: 1px solid white;width: 12px;height: 50%;border-left: none;border-top: none;align-self: end;
  /* margin-right: -1px; */
}

.csBreadcrumb.ui.breadcrumb a
{
  color: #4FDA03;
  text-shadow: 0px 0px black;
}
.csBreadcrumb {
  display: initial;
  flex-wrap: unset;
  padding: unset;
  margin-bottom: 0px;
  list-style: none;
  background-color: unset;
  border-radius: unset;
}

.ui.form .field select {
  display: block !important;
}

.ui.modal {
  top: unset;
  left: unset;
}

.ui.card,
i.icon {
  background-color: unset !important;
}

.ui.grid .row {
  margin: 0 !important;
}

.inmplayer-trigger {
  bottom: 10px;
  right: unset !important;
  left: -225px !important;

}

.inmplayer-launcher {
  z-index: 5!important;
}

@media print {
  @page {
    size: portrait!important;
    margin: 10mm 0mm!important;
  }

  body {
    overflow-y: visible!important;
    background-color: #000!important;
  }

  * {
    overflow-y: visible!important;
    max-width: none!important;
    line-height: 140%!important;
  }

  .catalog-product-container {
    min-width: 80%!important;
  }

  p, span, li, h4 {
    font-size: 14pt!important;
  }

  label, h2, h3 {
    font-size: 16pt!important;
  }

  label {
    margin: 10px 0!important;
  }

  td {
    font-size: 14pt!important ;
  }
  h2 {
    margin: 5px 0!important;
  }

  h1 {
    font-size: 20px!important;
  }

  .product-link {
    font-size: 16pt!important;
    text-decoration: none!important;
    display: block!important;
  }

  .catalog-product-container .product-image img {
    min-height: 120pt;
    display: block;
    page-break-before: always!important;
  }

  #_page-toolbar,
   #_archived-version,
    button,
    select,
    input,
    #_page-scorecard-select,
    #_page-scorecard-text,
    .catalog-product-container .label-line,
    .CSUITabs .nav-tabs .nav-item .nav-link.CSUITabsHeader:not(.CSUITabsHeader-Active),
    textarea,
    .form-check,
    .star-rating
  {
    display: none!important;
  }

  .archive-version-info {
    display: flex;
    flex-direction: column;
  }

  #_page-rows {
    flex-direction: column-reverse!important;
  }

  #_page-rows > .col-6 {
    min-width: 100%!important;
    page-break-after: auto!important;
  }
}

@import './variables.scss';

.updated-toolbar 
{  

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background: $text_color;
    color: #FFF;
    
    
    h1 
    {
        text-transform: uppercase;
        padding: 0px;
        margin: 0px;
        padding-top: 11px;
        font-size: 16px;
        font-weight: 600;
        padding-left: 40px;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: 20px;
    }

    button:not(.exempted) 
    {
        height: 40px;
        color: #FFF;
        margin: 0px;
        padding: 0px;
        padding-left: 10px;
        padding-right: 10px;
        border: none;
        min-width: 7%;
        text-align: center;
        background-image: linear-gradient(to bottom right, $text_color, rgb(89,89,89));
        font-weight: 300;
        font-size: 13px;

        span 
        {
            padding-top: 3px;
        }
    }
    button.large {
        min-width: 15.5%;
    }
    button.larger {
        min-width: 20%;
    }
    .bar-inputs-container {
        padding-top: 7px!important;
    }
    .mdb-main-label {
        border:none!important;
    }
    button:not(.active):hover 
    {
        background:rgb(89,89,89);
    }
    button.exempted:hover {
        background:none!important;
    }
    button.active {
        background-image: linear-gradient(to bottom right, rgb(112,48,160), rgb(190,44,230));
    }
    .icon 
    {
        margin-right: 10px;
    
        img 
        {
            width: 18px;
            height: 18px;
        }
    }

}
.updated-toolbar.round {
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.standard-header-title {
    font-weight: 900;
    font-size: 22px;
    color: $cs_color!important;
    margin: 0px;
    padding: 0px;
  }
  .standard-header-title.without-subtitle {
      margin-bottom: 20px;
  }
  .standard-subtitle {
    font-weight: 700;
    font-size: 16px;
    color: $text_color!important;
    margin: 0px;
    padding: 0px;
  }
  .standard-subtitle.indented {
      padding-left: 40px;
  }
.standard-header-subtext {
    font-weight: 400;
    font-family: "Montserrat", Lato, "Helvetica Neue", Arial, Helvetica,
      sans-serif !important;
    color: $text_color;
    font-size: $default_font_size;
}
.standard-subtext {
    font-weight: 400;
    font-family: "Montserrat", Lato, "Helvetica Neue", Arial, Helvetica,
      sans-serif !important;
    color: $text_color;
    font-size: 12px;
}
div.ul {
    div {
        padding-left: 20px;
        position: relative;
        margin-top: 5px;
    }
    div.small {
        font-size: 11.5px;
        margin:0px;
    }
    div::before {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 110%;
        background: #000;
        top: 7px;
        left: 0px;
        content:"";
    }
}
.large-grey-header {
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background: $gray_bg;
    border-radius: 3px;
    color: $text_color;
    font-weight: 700;
    font-size: 12px!important;
    margin: 0px;
    margin-bottom: 20px;
}
.list-header {
    color: $light_text_color;
    background: $gray_bg;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 600;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 10px;
    padding-left: 5px;
    .right-label {
        float: right;
        font-size: 11px;
        font-weight: 400;
        padding-right: 10px;
    }
}
.list-header.indented {
    padding-left: 20px;
}

.list-header.without-margin {
    margin: 0px;
}

.flex-table-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .flex-table-column:not(:first-child) {
        margin-left: 5px;
    }
    .flex-table-column.w25pct {
        width: 25%;
    }
    .flex-table-column.w10pct {
        width: 10%;
    }
    .flex-table-column.w20pct {
        width: 20%;
    }
    .flex-table-column.w45pct {
        width: 45%;
    }
}
.cs-color {
    color:$cs_color;
}
.white-container-bg {
    background: #FFF;
}
.dark-container-bg {
    background: #f5f5f5;
}
.with-round-container-border {
    border:solid 1px;
    border-color: $borders_color!important;
    border-radius: 5px;
}
.with-round-container-border.with-padding {
    padding:20px;
}
.with-round-container-border.with-small-padding {
    padding:10px;
}
.with-round-container-border.with-margin-top {
    margin-top:30px!important;
}
.with-round-container-border.with-margin-bottom {
    margin-bottom:30px!important;
}
.standard-column-list-container {
    height:200px;
    display:block;
    width:100%;
    padding:10px;
    outline: none;
    box-shadow: none;
    -webkit-outline:none;
    overflow-x: hidden;
    overflow-y: auto;
    .editable-list-item {
        display: block;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        i {
            margin-left: 10px;
            cursor: pointer!important;
        }
    }
    .no-items-notice {
        font-size: 10px;
        text-align: center;
        padding: 20px;
        color: #666;
        line-height: 10px;
    }
}
textarea.standard-column-list-container {
    font-size: 12px;
    line-height: 12px;
}
.standard-column-list-container.without-padding {
    padding:0px;
}
.flex-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    .width20pct {
        width:20%;
    }
    .width10pct {
        width:10%;
    }
    .width20pct:not(:last-child) {
        margin-right: 10px;
    }
    
}
.table-list-zoomed {
    li {
        .tr-columns {
            font-size: 10px !important;
        }
    }
}

ul.table-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    overflow-y: auto;
    padding-right: 0px;
    li {
        display: block;
        margin-bottom: 10px;
        .bold {
            font-weight: 500;
            display: inline-block;
            font-size: 12px;
        }
        .bolder {
            font-weight: 700;
            color: red;
        }
        .tr-columns {
            display: flex;
            flex-direction: row;
            border:solid 1px $borders_color;
            border-radius: 5px;
            padding: 3px;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 13px;
            color:$text_color;
            .bold {
                margin-right: 10px;
            }
            .vertically-aligned {
                padding-top: 5px;
            }
            .width3pct {
                width:3%;
            }
            .width10pct {
                width:10%;
            }
            .width5pct {
                width:5%;
            }
            .width40pct {
                width:40%;
            }
            .width25pct {
                width:25%;
            }
            button.options-toggler {
                
                background: none;
                border:none;
                padding:0px;
                margin:0px;
                margin-left: 10px;
                margin-top: 3px;
                display: inline-block;
                width:20px;
                height:20px;
                outline: none;
                box-shadow: none;
                -webkit-outline: none;
                background:url('../../media/KeywordsIcons/expand.svg');
                background-size: 16px 16px;
                background-position: center;
                background-repeat: no-repeat;
            }
            button.options-toggler.expanded {
                background:url('../../media/KeywordsIcons/collapse.svg');
                background-size: 16px 16px;
                background-position: center;
                background-repeat: no-repeat;
            }
            img {
                display: inline-block;
                max-width: 32px;
                max-height: 32px;
            }
        }
        
    }
    li .tr-columns:not(.disabled) {
        cursor: pointer;
    }
    li .tr-columns:not(.disabled):hover {
        background-color: #eeeeee;
    }
}
h5.standardh5 {
    font-weight: 700!important;
    font-size: 11.5px;
    margin: 0px!important;
    padding: 0px!important;
}
.smaller-font {
    font-size: 11.5px;
}

.standard-confirmation-dialog 
{
    z-index: 999;
    position: fixed;
    background: #FFF;
    box-shadow: 0px 2px 10px #999;
    padding: 30px;
    border-radius: 3px;
    top: 30%;
    left: 30%;
    width: 40%;
    font-size: 22px;
    color: #404040;
    text-align: center;

    p 
    {
        font-weight: 300;
        font-size: 16px;
    }

    h1 
    {
        color: #404040;
        font-size: 20px;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }

    .options 
    {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        button 
        {
            flex-grow: 1;
            margin:10px;
            background: #FFF;
            border-radius: 10px;
            box-shadow: 0px 2px 10px #666;
            padding: 20px 15px;
            color: #404040;
            font-size: 16px;
            text-align: center;
            font-weight: 300;

            b 
            {
                font-weight: 700;
            }
            .with-warning {
                color:red;
            }
        }

        button.large 
        {
            padding: 20px 15px;

            b 
            {
                font-size: 30px;
                font-weight: 300;
            }
        }

        button:hover 
        {
            box-shadow: 0px 2px 15px #999;
        }
    }
}

.form-check input[value="true"] + label:after
{
    background-color: indigo !important;
    border-color: indigo !important;
}
.little-header {
    font-size: 10px;
    font-weight: 700;
}
.little-header.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}
.boxed-content {
    border: solid 1px $borders_color;
    padding: 10px;
    margin: 10px;
    border-radius: 3px;
    font-size: 11px;
    color: $text_color;
}
.highlighted-word {
    background: yellow;
}
.error-message {
    color:red;
    font-size: 11px;
}
.error-message::before {
    content:"* "
}
.input-column {
    padding-right: 0px!important;
}
.buton-column {
    padding-left: 1px!important;
    padding-top: 12px;
}
.buton-column button {
    width: 95%!important;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
}

.custom-dropdown
{
    cursor: pointer;
    max-width: 100%;
    background-color: #FFFF;
    border:solid 1px #CCC;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: #8a8a8a;
    font-size: 14px;
    position: relative;
    background-image: url('../img/svg/down.svg');
    background-position: 98% 8px;
    background-size: 10px 10px;
    background-repeat: no-repeat;
}
.custom-dropdown.material {
    border-color: #000;
    background-color:transparent;
    border-radius: 0px;
    border:none;
    padding-left: 5px;
    color: $text_color;
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-position: 98% 7px;
    background-size: 8px 8px;
}

ul.custom-dropdown-options
{
    list-style: none;
    background: #FFFF;
    z-index: 10;
    padding:3px;
    padding-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
    border: solid 1px #7b9bd3;
}
ul.custom-dropdown-options.material {
    padding-bottom: 10px;
}
ul.custom-dropdown-options li
{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
}

ul.custom-dropdown-options li.option
{
    color: #8a8a8a;
    font-size: 13px;
}
ul.custom-dropdown-options.material li.option {
    padding-left: 5px!important;
    padding-right: 5px!important;
    font-size: 12px;
    color: $text_color;
}
ul.custom-dropdown-options li.option.level-1
{
    padding-left: 40px!important;
}

ul.custom-dropdown-options li.disabled:not(.visible)
{
    opacity: 0.5;
}
ul.custom-dropdown-options li.optgroup
{
    margin-top: 20px;
    margin-bottom: 10px;
    color: #7f7f7f;
    border-bottom: solid 1px #7b9bd3;
    border-top: solid 1px #7b9bd3;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}
ul.custom-dropdown-options li.option:not(.disabled)
{
    cursor: pointer!important;
}
ul.custom-dropdown-options li.option:not(.disabled):hover
{
    background: rgba(184, 183, 183, 0.1);
}
.cs-inputs-container {
    .md-form input:not([type]):focus:not([readonly]), 
  .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="search-md"]:focus:not([readonly]), 
  .md-form textarea.md-textarea:focus:not([readonly]) 
  {

    border-bottom: 1px solid indigo;
    box-shadow: 0 1px 0 0 indigo;
  }

  .md-form input:not([type]):focus:not([readonly]) + label, 
  .md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="password"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="email"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="number"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="search"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="search-md"]:focus:not([readonly]) + label, 
  .md-form textarea.md-textarea:focus:not([readonly]) + label,
  ul.dropdown-content.select-dropdown.fadeElement.fadeIn + label.text-primary
  {
    color: indigo !important;
  }

  ul.dropdown-content li > span
  {
    color: #666666 !important;
  }

  ul.dropdown-content li.active > span:hover,
  .dropdown-content li > span:hover
  {
    background-color: indigo !important;
    color: white !important;
  }

  ul.dropdown-content li.active > span
  {
    color: indigo !important;
  }
  
  ul.dropdown-content
  {
    max-height: 20rem;
  }

  .mdb-main-label.active.text-primary
  {
    color: #757575 !important;
  }
  .aligned-cs-input {
    height: 26px!important;
  }
  .md-form {
      margin-bottom: 0px!important;
      margin-top: 0px!important;
  }
}

.rt-copy-container {
    border:solid 1px;
    border-color: $borders_color!important;
    border-radius: 5px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    padding-top: 0px;
    padding-left: 20px;
    .table-columns-container {
        min-width: 660px;
    }
    .action-button-container {
        width: 250px;
        padding-top: 35px;
        padding-left: 20px;
        padding-right: 20px;
        
    }
    .row-container {
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px $borders_color!important;
    }
    .row-container:nth-child(even) {
        background-color: #F7F7F7;
    }
    .column-container {

        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
        width: 120px;
        position: relative;
    }
    .column-container input.plain-cs-input {
        background: transparent;
        border: none;
        font-size: 12px;
        font-weight: 300!important;
        outline: none;
        box-shadow: none;
        -webkit-box-shadow:none;
        width: 95%!important;
        color: #464646;
        text-align: left;
        display: block;
        padding-top: 4px;
    }
    .column-container:last-child {
        flex:300px 0;
    }
    .column-container:not(:first-child) {
        border-left: solid 1px $borders_color;
       
    }
    .column-container header {
        font-weight: bold; 
        font-size: 10pt; 
        text-align: left;
        border-bottom: 1px solid indigo; 
        padding: 5px 0px;
        margin-bottom: 0px;
    }
    .column-container header span {
        position: relative;
    }
    .column-container header span::after {
        position: absolute;
        top: -3px;
        right: -7px;
        color: red;
        content:" *"
    }
    .column-container input{
        margin-bottom: 0px!important;
        border:none!important;
    }
    .row-container:not(:first-child) .column-container header {
        display: none;
    }
    button.deleteUser {
        background: none;
        color: red;
        font-size: 12px;
        border:none;
        padding: 0px;
        position: absolute;
        left: -15px;
        bottom: 12px;
    }
    .row-container:last-child .column-container:first-child::before {
        position: absolute;
        font-size: 12px;
        left: -15px;
        bottom: 12px;
        color: #4FDA01;
        content:"+";
        font-weight: bold;
    }
}
.vertically-aligned {
    .rt-td {
        padding-top: 10px;
        padding-bottom: 5px;
        height: 40px;
    }
}
.boldened {
    font-weight: 700;
}
.boldened.with-warning {
    color: red;
}
.icon18px img {
    width: 18px;
    height: 18px;
}

.tableTitle span {
    font-size: 12px;
    font-weight: 300;
}
.p-like {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
}

.underlined-link {
    color: $text_color!important;
    font-size: 12px;
    text-decoration: none;
    display: inline-block;
    border-bottom: solid 1px $text_color;
    line-height: 12px;
}
.plain-link {
    color: $text_color!important;
    font-size: 12px;
    text-decoration: none;
    display: inline-block;
    line-height: 12px;
}
.smaller-link {
    font-size: 11px;
}
.standard-link {
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.5;
    }
    &.block {
        display: block;
    }
}
.card {
    .card-body.standard-size {
        min-height: 450px;
    }
}
.pagination-controls {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pagination-controls .pagination-controls__button {
  display: flex;
  align-items: center;
  color: #728899;
  font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0 1em;
  margin-left: 2px;
  margin-right: 2px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
}

.pagination-controls .pagination-controls__button:first-child {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination-controls .pagination-controls__button:last-child {
  margin-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination-controls .pagination-controls__button:hover:not(:active) {
  transition: transform 0.15s;
  transform: translateY(-1px);
}

.pagination-controls .pagination-controls__button--active {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  background: #6b19b7;
  padding: 10px 20px;
}

.pagination-controls .pagination-controls__button--active:hover, .pagination-controls__button--active:hover:not(:active) {
  transform: none;
}
.job-error-dialog 
{
    z-index: 999;
    position: fixed;
    background: #FFF;
    box-shadow: 0px 2px 10px #999;
    padding: 30px;
    border-radius: 3px;
    top: 30%;
    left: 30%;
    width: 40%;
    font-size: 22px;
    color: #404040;
    text-align: center;

    p 
    {
        font-weight: 300;
        font-size: 16px;
    }

    p.margin-bottom 
    {
        margin-bottom: 30px;
    }

    h1 
    {
        color: #404040;
        font-size: 20px;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }
}
.newNavbar 
{
    background: rgb(102,0,158);
    background: -moz-linear-gradient(176deg, rgba(102,0,158,1) 0%, rgba(190,44,213,1) 56%, rgba(102,0,158,1) 100%);
    background: -webkit-linear-gradient(176deg, rgba(102,0,158,1) 0%, rgba(190,44,213,1) 56%, rgba(102,0,158,1) 100%);
    background: linear-gradient(176deg, rgba(102,0,158,1) 0%, rgba(190,44,213,1) 56%, rgba(102,0,158,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#66009e",endColorstr="#66009e",GradientType=1); 

    //&:hover,
    > a:hover 
    {
        background-color: rgba(0,0,0,.1) !important;
    }

    > a img
    {
        height: 30px;
    }
}
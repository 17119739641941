.catalog-product-container {
  .archived-version-container {
    margin-top: 0px;
    padding: 10px 15px;
    overflow: visible;

    .white-container {
      padding-bottom: unset;
    }
  }

  .no-padding {
    padding: unset !important;
  }

  .top-card {
      margin-bottom: 15px;
  }

  .white-container {
      padding: 10px 15px;
      padding-bottom: unset;
  }
}

.dual {
  white-space: nowrap;
}

.workflow-stage-header {
  display: flex;
  align-items: center;

  b {
    margin-left: auto;
  }
}

.archive-version-info {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.archive-version-info .main-content {
  width: 60%;
}

.catalog-product-container .equal-height {
  min-height: 100px;
}
.catalog-product-container .equal-height .column {
  min-height: 100px;
}
.archive-version-info .values {
  width: 20%;
  padding-left: 20px;
  flex-direction: row;
}

.archive-version-info .values .column {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: flex-start;
}
.archive-version-info .values .column .field {
  text-align: left;
  width: 100%;
}
.archive-version-info .prices {
  padding-left: 20px;
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

@media (max-width: 1400px) {
  .archive-version-info .main-content {
    width: 50%;
  }
  .archive-version-info .values {
    width: 25%;
  }
}

.csui_boldheader
{
    text-transform: uppercase;
    color: indigo;
    
    .boldPart
    {
        font-weight: 900;
    }
    .plainPart
    {
        font-weight: 300;
    }
}

/* h1.custom {
    font-weight: 300;
    font-size: 18px;
    padding-top: 40px;
  }
  h1.custom span {
    font-weight: 900;
  } */
button.csui_button
{
    font-family: 'Montserrat', Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    font-weight: 700 !important;
    font-size: 1rem !important;
    text-transform: none !important;
    margin: 0;
    margin-right: 4px !important;
    padding: 0.8rem 2.14rem !important;
    line-height: 1em !important;
    border: 1px solid !important;
    border-radius: 0px;
    color: white;

    &.btn-default
    {
        border: 1px solid !important;

        &:not([disabled]):not(.disabled):active
        {
            background-color: transparent !important;
        }
    }

    &.csui_purple
    {
        &, &:active 
        {
            background-color: indigo !important;
            border-color: indigo !important;
        }

        &.csui_button_outlined
        {
            color: indigo !important;
        }
    }

    &.csui_red
    {
        &, &:active 
        {
            background-color: #d32f2f !important;
            border-color: #d32f2f !important;
        }

        &.csui_button_outlined
        {
            color: #d32f2f  !important;
        }
    }

    &.csui_green
    {
        &, &:active 
        {
            background-color: #4FDA03 !important;
            border-color: #4FDA03 !important;
        }

        &.csui_button_outlined
        {
            color: #4FDA03 !important;
            border-width: 1px;
        }
    }

    &.csui_blue
    {
        &, &:active 
        {
            background-color: #084a8e !important;
            border-color: #084a8e !important;
        }

        &.csui_button_outlined
        {
            color: #084a8e !important;
        }
    }

    &.csui_grey 
    {
        &, &:active 
        {
            background-color: rgb(175, 175, 175) !important;
            border-color: rgb(175, 175, 175) !important;
        }

        &.csui_button_outlined
        {
            color: rgb(73, 73, 73) !important;
        }
    }

    &.csui_lightgrey 
    {
        &, &:active 
        {
            background-color: #afafaf !important;
            border-color: #afafaf !important;
        }

        &.csui_button_outlined
        {
            color: #afafaf !important;
        }
    }

    &.csui_lightred
    {
        &, &:active 
        {
            background-color: #d32f2f !important;
            border-color: #d32f2f !important;
        }

        &.csui_button_outlined
        {
            color: #d32f2f !important;
        }
    }

    &.csui_button_slim
    {
        padding: .46em 1em !important;
    }

    &.csui_button_outlined 
    {
        background-color: transparent !important;
    }
    &.csui_button_icon
    {
        font-weight: 300!important;
        color:#333!important;
        padding-left: 5px!important;
        padding-right: 5px!important;
        border-color: transparent !important;
        &:hover {
            border-color: transparent !important;
            opacity: 0.6;
        }
        &:active {
            border-color: transparent !important;
            opacity: 0.3;
        }
    }
    
    
    &.csui_button_disabled
    {
        /* background-color: rgb(175, 175, 175) !important; */
        /* border-color: rgb(175, 175, 175) !important; */
        border-width: 1px !important;
        opacity: .65;
        color: white;

        &.csui_button_outlined
        {
            /* background-color: rgb(175, 175, 175) !important; */
            color: rgb(73, 73, 73) !important;
        }

        &:not(.csui_button_outlined):after {
            background-color: rgba(0,0,0,.35) !important;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content:'';
        }
    }

    &.csui_button_rounded
    {
        border-radius: 5px;
    }

    &.csui_button_flat 
    {
        box-shadow: none !important;

        &:not(.csui_button_icon):hover
        {
            &:after {
                background-color: rgba(0,0,0,.1) !important;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                content:'';
            }
        }

    }
    
    &:not(.csui_button_icon):active,
    &.csui_button_flat:not(.csui_button_icon):active
    {
        &:after {
            background-color: rgba(0,0,0,.4) !important;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content:'';
        }
    }

}

.btn-block
{
    display: block;
}

.csui_buttonCloseXParent.closebutton-topright
{
    position: absolute;
    right: -6px;
    top: -4px;
}

.csui_buttonCloseX
{
    max-width: 30px;
    min-width: unset !important;
    max-height: 30px;
    height: 30px;
    z-index: 3;
    background-color: #212121 !important;
    border-radius: 50% !important;

    & .MuiButton-startIcon {
        margin-right: unset;
        margin-left: unset;
    }

    svg {
        font-size: 14px !important;
        path {
            color: white;
        }
    }

    &.btn
    {
        padding: 7.7px 0;
    }

    &:active, &:focus, &:hover 
    {
        outline: 0;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    }
}

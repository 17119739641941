.mapping-title
{
    color: #8a8a8a;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
}
.mapping-title.with-margin-top
{
    margin-top: 50px;
}

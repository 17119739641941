.myjobs-wrapper
{
    padding-top: 20px;

    .dash-row2
    {
        .h1-simple
        {
            margin-top: 0px !important;
        }
    }
}
.tags-snapshot {
  .snapshot-body {
    margin-top: 20px;
  }

  .section-header {
    margin: 10px 0;
    padding: 5px;
    display: block;
    font-size: 11px;
    font-weight: 600;
    background-color: #eee;
    color: #7f7f7f;
    border-radius: 2px;
  }
}

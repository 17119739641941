.lists {
    margin: 0;
    padding: 0;
    h2 {
        border-bottom: 1px solid #dee2e6;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold!important;
    }

    h3 {
        font-size: 12px;
        font-weight: bold!important;
    }
    
    .row {
        display: grid;
        grid-template-columns: 8fr 4fr;
        grid-gap: 10px;
    
        .col1 {

            h4 {
                font-size: 12px;
            }
        }

        .col2 {

                margin-left: 0px!important;
                padding-left: 20px;
                margin-bottom: 20px;
                li {
                    margin-bottom: 4px;
                    list-style: none;
                    position: relative;
                    font-size: 12px;
                    display: flex;
                    justify-content: space-between;


                    div {
                        max-width: 180px;
                        width: 100%;
                    }
                }
                .li_critical {
                    &::before{
                        top: 6px;
                        left: -13px;
                        position: absolute;
                        content: '';
                        height: 8px;
                        width: 8px;
                        // background-color: url('../../../../media/CatalogProductIcons/warning.svg');  
                        background-image: url("./imgs/image.png") no-repeat cover;
                    }
                }
    
                
        }

        // .col3 {
        //     .checkbox {
        //         padding: 0;
        //     }
        // }
    }

    .row_warnings {
        .li_warning {
            font-weight: bold;
        }

        .grid {
            display: grid;
            grid-template-columns: 2fr 10fr;
            grid-gap: 10px;

            .col1 {

                .subheader {
                    margin-left: 15px;
                    position: relative;

                    &::before {
                        top: 4px;
                        left: -13px;
                        position: absolute;
                        content: '';
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        // background-color: url('../../../../media/CatalogProductIcons/warning.svg');  
                        background-color: #FEBE00; 
                    }
                }
            }

            .col2 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 4px;
            }
        }

    }
}

.report {
    .separator {
        height: 1px;
        box-sizing: border-box;
        width: 100%;
        background-color: #dee2e6;
        margin: 25px 0;
    }

    .report_content {
        h3 {
            font-size: 16px;
            font-weight: bold!important;
            margin-bottom: 0;
        }
    }
}
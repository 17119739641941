
@import '../../assets/scss/variables.scss';

.catalog-product-container {
    display: block;
    width: 100%;
    background: #F5F5F5;
    padding: 20px;
    /* margin-top: -50px; */
    min-width: 1280px !important;
    font-size: 1rem;

    > div
    {
        margin-bottom: 20px;
    }

    .variation {
        font-weight: 700!important;
    }

    .dataLoader
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 10%;
        min-height: 300px;

        .md-progress .indeterminate
        {
            background-color: rgba(75, 0, 130, .75);
        }

        .loaderHeader
        {
            font-size: 1.4rem;
            color: indigo;
            margin-bottom: 30px;
        }
    }

    .card:not(.csui_segment) {
        box-shadow: none !important;
    }

    .coming-soon {
        position: relative;
    }

    .coming-soon::after {
        position: absolute;
        right:160px;
        top: 0px;
        font-size: 9px!important;
        color: rgb(87,87,87);
        content:"(Coming Soon)";
        font-weight: 500;
        text-transform: none;
    }

    h1.section-header {
        margin-bottom: 10px;
    }

    .white-container
    {
        background-color: white;
        border: solid 1px #d8d8d8;
    }

    .dark-container
    {
        background: #f2f2f2 !important;
        border: 1px solid lightgrey !important;
        margin-top: 20px;
        height: auto !important;
        position: relative;

        .header-icon {
            position: absolute;
            top: 10px;
            right: 10px;

            img {
                width: 28px;
                height: 28px;
            }
        }

        > .row:first-child
        {
            margin-bottom: 1.25rem;
        }

    }

    .select-dropdown {
        top:0px!important;
    }

    .input-container .select {
        border: solid 1px lightgrey!important;

    }
    .icon
    {
        margin-right: 10px;

        img
        {
            width: 18px;
            height: 18px;
        }
    }

    .icon-button
    {
        margin-left: 5px!important;
        margin-right: 5px!important;
        padding: 0px;
        border: none;
        background: none;
        display: inline-block;

    }

    b.label {
        font-size: 13px;
    }

    .with-right-border {
        border-right: solid 1px lightgrey;
    }

    a.product-link {
        display: block;
        margin-bottom: 10px;
        padding-top: 10px;
        color: $text_color!important;
        font-size: 16px;
        font-weight: 900;
        text-decoration: underline;
    }
    .inline-block {
        width: 100%;
        display: inline-block;
    }
    .product-category {
        margin-bottom: 10px;
    }

    .label-line {
        color: $text_color;
        font-weight: 300;
        font-size: 11px;

        b
        {
            font-weight: 600;
        }
    }

    .product-image {
        border: solid 1px lightgrey;
        padding: 5px;
        text-align: center;
        display: inline-block;
        border-radius: 5px;
        margin-right: 20px;

        img {
            max-width: 100%;
        }
    }

    .product-image.small img {
        max-height: 50px;
    }
    .product-image.medium img {
        max-height: 80px;
    }
}

.with-margin-top {
    margin-top: 10px!important;
}

h1.dual {
    font-size: 16px!important;
    font-weight: 900!important;
    text-transform: uppercase!important;
    color: $text_color!important;
}

h1.dual span {
    font-weight: 300!important;
}

button.close-link {
    color: $text_color;
    font-weight: 300;
    font-size: 11px;
    background: none;
    padding: 0px;
    text-decoration: underline;
    border: none;
    outline: none;
    box-shadow: none;
    margin-left: 20px;
}

button.close-section {
    padding: 5px;
    border-radius: 100%;
    background: #000;
    color: #FFF;
    font-weight: 500;
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
}

/* .white-container {
    background: #FFF;
    border:1px solid lightgrey!important;
    margin-top: 20px;
} */

.no-padding-right {
    padding-right: 0px!important;
    margin-right: 0px!important;
}

.with-margin-bottom {
    margin-bottom: 10px;
}

.product-link.no-margin {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.with-border-bottom {
    border-bottom: solid 1px $borders_color;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.pull-left {
    float: left;
}

label.field,
.field-content,
.field-content b
{
    color: $text_color;
    font-weight: 400;
    display: block;
    margin: 0px;
    padding:0px
}

label.field.solid {
    font-weight: 600;
}

label.field.solid.indent {
    padding-left: 5px;
}

label.field.smaller {
    font-size: 13px;
}

label.field.multi-line {
    line-height: 12px;
}

label.field.little {
    font-size: 9.5px;
    height: 12px!important;
}

label.field.price {
    font-size: 20px;
    font-weight: 700;
}

div.column {
    display: inline-block;
    margin-right: 20px;
}

.top-margin {
    margin-top: 10px;
}

.padded {
    margin-left: 10px!important;
    margin-right: 10px!important;
}


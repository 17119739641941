.notifications-container
{
    padding-top: 20px;

    .notifications-header
    {
        margin-bottom: 20px;
    }
}

.notifications-holder 
{
    background-color: rgba(1,1,1,.03);
    border-radius: 6px;
    border: 1px solid rgba(1,1,1,.12);
}

.notification-container
{
    margin-top: 10px;
}
.notification-container .card
{
    padding-left: 10px;
    border-left: solid 5px;
}
.notification
{
    position: relative;
}
.notification .content
{
    width: 80%;
}
.notification .date
{
    position: absolute;
    top: -10px;
    right: -5px;
    color: #CCC;
    font-size: 10px;
    text-transform: uppercase;
}
.notification .content h1
{
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    font-weight: 600!important;
}
.notification .icon
{
    margin-right: 20px;
    font-size: 50px;
}
.notification .cta
{
    padding-top: 10px;
    width: 15%;
}
.notification .cta button
{
    display: block;
    width: 100%;
}
.width-padding
{
    padding-top: 20px;
    padding-bottom: 20px;
}
.notification a
{
    text-decoration: none!important;
    font-size: 16px;
    font-weight: 600;
}
.jobTableBalanceInfo
{
    display: flex;
    margin-left: auto;

    div
    {
        margin-right: 40px;

        span.label
        {
            font-weight: 500;
            color: black;

            > span
            {
                font-weight: 400;
            }
        }
    }
}

.disabled {
    pointer-events: none;
}

.newJobsTable.csui_mui_xgrid 
{
    .MuiDataGrid-columnsContainer
    {
        justify-content: center;
        height: 100%;
    }
    .grid-header
    {
        line-height: 70%;
        font-size: 14px;
        font-weight: 500;

        .MuiDataGrid-columnHeaderTitleContainer
        {
            border-bottom: 1px solid indigo;
            > div:first-child
            {
                width: 100%;
                border: none !important;
            }
        }
    }

    .MuiDataGrid-cell
    {
        font-size: 1rem;
    }
}
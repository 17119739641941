.newUserRegistrationContainer
{
    
    > div
    {
        display: flex;
        flex-direction: column;
        margin: 0 10%;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .white-navbar {
        background-color: #FFF!important;
        margin-bottom: 40px;
    }
}
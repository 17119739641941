.CSUIPivotTable 
{
    .bandRow
    {
        .topHeaderRow,
        .bottomHeaderRow
        {
            font-weight: 600;
        }
    }

    .bottomHeaderRow
    {
        font-weight: 400;
    }
}

.ReportsLayout
{

    div.MuiToolbar-root
    {
        border-bottom: none;
    }

    .headerText
    {
        margin-bottom: 10px;

        
    }

    b
    {
        font-weight: 700;
    }

    .navButtons
    {
        display: flex;
        margin-left: 10px;

        @media screen and (max-width: 1175px) {
            flex-wrap: wrap;
        }

        > div
        {
            display: flex;
            flex-direction: column;
            width: 200px;
            margin-left: 5px;
            justify-content: center;

            > a
            {
                margin-bottom: 5px;

                > button
                {
                    background-color: white;
                    border-radius: 5px;
                    justify-content: start;

                    .reports-link-title,
                    .reports-link-subtitle
                    {
                        text-transform: none;
                    }
                }
            }

            > a.reports-link-active
            {
                > button
                {
                    background-color: #A024B4;
                }
                color: white;

                .reports-link-title,
                .reports-link-subtitle
                {
                    color: white;
                }
            }
        }

        button
        {
            width: 100%;
        }

        .reports-link-title
        {
            color: #A024B4;
            font-weight: 700;
            margin-right: 5px;
        }
    }
}
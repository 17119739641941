.cs-success-dialog 
{
    /* z-index: 999;
    position: fixed;
    top: 30%;
    left: 30%;
    width: 40%; */
    background: #FFF;
    box-shadow: 0px 2px 10px #999;
    padding: 30px;
    padding-left: 65px;
    padding-right: 65px;
    border-radius: 3px;
    font-size: 22px;
    color: #404040;
    text-align: center;

    p 
    {
        font-weight: 300;
        font-size: 14px;
    }

    h1 
    {
        color: #404040;
        font-size: 21px;
        text-align: center;
        margin: 0px;
        padding: 0px;
        margin-bottom: 10px;
    }

    .options 
    {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        justify-content: center;
        button {
            margin-left: 10px;
            margin-right: 10px;
            width: auto;
            min-width: 90px;
            
        }
        
    }
}
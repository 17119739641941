i.csui_icon.icon
{
    // display: inline-flex;
    align-items: center;
}

.cs-table-icon-link img,.cs-table-icon-button img {
    width: 20px;
    height: 20px;
}
.cs-table-icon-link.small img,.cs-table-icon-button.small img {
    width: 16px;
    height: 16px;
}
.cs-table-icon-button {
    border: none;
    margin: 0px;
    padding: 0px;
    background:none;
    outline: none!important;
    box-shadow: none!important;
}
.cs-table-icon-link,.cs-table-icon-button {
    margin-left: 10px;
    margin-right: 10px;
}
@import '../../../../assets/scss/variables.scss';

.content-snapshot {
    .top-card {
        padding: unset;
    }

    .logo-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .prime-logo {
            width: 50px;
            margin-right: 20px;
        }
    }

    .field.little.solid
    {
        line-height: 10px;
    }

    .BuyBoxInfo
    {
        //height: 50px;

        > div
        {
            > :nth-child(1)
            {
                min-width: 80px
            }

            > :nth-child(2)
            {
                min-width: 110px
            }

            > :nth-child(3)
            {
                min-width: 100px
            }
        }

        .column
        {
            display: flex;
            flex-direction: column;
        }

        .labelTitle
        {
            font-size: 10px;
        }

        .infoText
        {
            font-size: 14px;
            font-weight: bold;
        }

        .infoPrice
        {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

h1.dual.margin-left {
    margin-left: 10px;
}

.product-image-audit-row
{
    display: flex;
    flex-wrap: wrap;

    .product-image-audit
    {

        width: 62px;
        height: 62px;
    }
}

.non-graded-specs
{
    position: relative;
    padding-top: 20px;

    > div
    {
        label
        {
            margin-bottom: 10px;
        }

        table
        {
            td
            {
                padding-top: .5rem;
                padding-bottom: .5rem;

                &:first-child
                {
                    width: 220px;
                    font-weight: bold;
                }

                &:last-child
                {
                    width: 350px;
                }
            }
        }
    }

    &::before
    {
        content:"Non-Graded Specifications";
        position: absolute;
        top: -20px;
        left: 0px;
        width: 100%;
        font-size: 10px;
        color: $text_color;
        font-weight: 600;
        text-align: center;
    }
}

.video-thumbnail {
    background: #f2f2f2;
    border:solid 1px $borders_color;
    border-radius: 5px;
    width: 72px;
    height: 72px;
    padding: 10px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.thumbnail-image-wrapper
{

    border: solid 1px lightgrey;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    margin-right: 20px;
    width: 70px;
    height: 70px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    a.thumbnail-image {
        text-decoration: none;
        display: inline-block;

        font-size: 3rem;
        color: darkgrey;

        img {
            max-height: 70px;
            border: solid 1px $borders_color;
        }
    }

}

.video-thumbnail::after {
    position: absolute;
    left: 35%;
    top: 30%;
    width: 24px;
    height: 24px;
    background:url('../../../../media/CatalogProductIcons/play.svg');
    background-size: 24px 24px;
    content:"";
    opacity: 0.8;
}

.video-thumbnail img {
    width: 52px;
    height: 52px;
}

.catalog-product-container .price-line {
    margin-bottom: 20px;
    color: $text_color;
    font-weight: 300;
    font-size: 11px;
}

.catalog-product-container .price-line b {
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px;
}

ul.bullets-list {
    list-style: none;
    margin:0px;
    padding: 0px;
    margin-bottom: 20px;
}

ul.bullets-list li {
    // font-size: 14px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 3px;
    padding-left: 10px;
    position: relative;
}

ul.bullets-list li::before {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%!important;
    background: $text_color;

    content:"";
    left: 0px;
    top: 8px;
}

.column.max-75 {
    max-width: 75%;
}

@media (max-width:1400px) {
    .catalog-product-container  .product-image.small img {
        max-height: 40px;
    }
    .catalog-product-container  .product-image.medium img {
        max-height: 60px;
    }
}

.image-scores
{
    .score-display
    {
        padding-bottom: 15px;
    }
}

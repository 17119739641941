.CSUIPivotTable
{
    .MuiTableCell-root.MuiTableCell-head
    {
        padding-top: 5px;
        padding-bottom: 5px;

        border-right: none;
    }

    td.MuiTableCell-root
    {
        border-bottom: none;
    }
}
button.checkmark-toggler {
    float: right;
    width: 16px;
    height: 16px;
    border: none;
    background-color: none;
    outline: none;
    box-shadow: none;
    -webkit-box-shadow:none;
    background-image: url('../../../../media/ScorecardsIcons/unchecked.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    margin-top: 2px;
    margin-left: 20px!important;

    &.green-checkmark {
        background-image: url('../../../../media/ScorecardsIcons/checked.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    &.yellow-checkmark {
        background-image: url('../../../../media/ScorecardsIcons/partial-check.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
}

.imagePopupTrigger
{
    display: flex;
    justify-content: center;

    img
    {
        max-width: 50px;
        max-height: 50px;
    }
}

.imagePopupContent
{
    display: flex;
    justify-content: center;

    img
    {
        max-width: 200px !important;
        max-height: 200px !important;
        width: auto;
        display: block;
    }
}

.popup-content.videoPopup-content
{
    width: auto !important;
}

.videoPopupTrigger
{
    height: 100%;
    width: 100%;
    cursor: pointer;

    > div
    {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;

    }
}
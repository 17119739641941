@import '../../../../assets/scss/variables.scss';

.keywords-table {
    // font-size: 14px;
    margin-bottom: 10px;

    .table-head 
    {
        div 
        {
            background: #f2f2f2 !important;
            // font-weight: 500;
        }
    }

    div.warning 
    {
        position: relative;

        &::before 
        {
            background-image: url('../../../../media/CatalogProductIcons/warning.svg');
            background-size: 8px 8px;
            background-repeat: no-repeat;
            width: 8px;
            height: 8px;
            content: "";
            position: absolute;
            left: 3px;
            top: 6px;
        }
    }
}

.inline-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
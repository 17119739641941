.billing-wrapper {
  padding-top: 20px;

  .billing-headerrow {
    display: flex;

    .billing-header {
      &-details {
        margin-bottom: 20px;
      }

      .h1-simple {
        margin-top: 0px !important;
      }
    }

    .header-button-area {
      margin-left: auto;
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1168px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .header-button {
        margin-left: 10px;
        word-wrap: nowrap;

        @media screen and (max-width: 1168px) {
          &:first-child {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .billingBalanceInfo {
    display: flex;
    margin-left: auto;

    div {
      margin-right: 40px;

      span.label {
        font-weight: 500;
        color: black;

        > span {
          font-weight: 400;
        }
      }
    }
  }

  .CSUITabs {
    .nav.nav-tabs.mt-5 {
      margin-top: 1rem !important;
    }
  }

  .column-wrapper {
    border-bottom: 1px solid indigo;
    padding: 5px 0;
    text-align: left;
  }

  .red-font {
    color: red;
  }
}

.publicNav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 505;
  height: 52px;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 12px;

  > img {
    height: 40px;
    padding-left: 8px;
  }
}

.mainContentDiv
{
  display: flex;
  flex-direction: column;

  .SamsDiv
  {
    background-color: #4F4FF8;
    color: white;
    height: 50px;
    margin-bottom: 10px;
    ;
    display: flex;
    justify-content: center;
    align-items: center;

    > div:first-child > span
    {
      font-weight: 700;
    }

    > div:last-child
    {
      margin-left: 20px;
    }
  }

  .buttonHolder
  {
    display: flex;
    //flex-direction: column;

    button 
    {
      margin-left: auto;
    }
  }
}
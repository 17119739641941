@media print
{

  /* @page {
    size: landscape;  
    margin: 0mm;
  } */


  body, 
  div,
  #digital-shelf-container,
  .csui_mui_xgrid  
  {
    background-color: white !important;
  }

  header.MuiPaper-root.MuiAppBar-root,
  .newNavbar,
  div#hubspot-messages-iframe-container
  {
    display: none!important;
  }
  

  #organic 
  {
    display: none !important;
  }

  #digital-shelf-container 
  {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 45764375;
    padding: 20px;
    height: fit-content !important;
    box-shadow: none;
    background-color: white !important;
    /* max-width: 900px !important; */
    
  }

  .sos-logo
    {
      > div > span
      {
        font-size: 3rem !important;
      }
    }

    #search-box-container
    {
      .search-row-print
      {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .retailer
        {
          margin-right: 20px;
          padding-right: 20px;
          text-align: right;
          border-right: 1px solid black;

          div:nth-child(1)
          {
            font-weight: 700;
            font-size: 2rem;
          }
        }

        .terms
        {
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
      .search-row
      {
        display: none !important;
      }
    }

    .charts-container
    {
      .chart-container
      {
        width: 315px
      }

      .chart-container ~ .chart-container {
        margin-left: 8px;
      }
    }

  
}

div#menu-retailer.MuiPopover-root
{
  div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  {
    //max-height: 140px !important;
    //max-height: 140px !important;
  }
}

#digital-shelf-container
{
  height: 100%;
  width: 100%;
  background-color: white;
  min-height: 165px;

  #organic-print
  {
    display: none;
  }

  .table-container
    {
      .MuiDataGrid-root
      {
        height: 600px !important;
        /* overflow-y: hidden; */
      }
    }

  .print-table
  {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    margin-top: 20px;

    .cs-row
    {
      display: flex;
      min-height: 100px;
      border-bottom: 1px solid lightgray;
      break-inside: avoid-page;
    }

    .cs-body-cell
    {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    .cs-header-row
    {
      height: 48px;
      min-height: 48px;
      background-color: rgb(238, 238, 238);
    }

    .cs-header-cell
    {
      padding-left: 15px;
    }
  }

  #sos-header
  {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sos-logo > div > span
    {
      font-size: 2.5rem;
    }
  }

  &.csui_segment
  {
    margin-bottom: 50px;
  }

  #results
  {
    text-align: center;
  }

  #public-results
  {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: black;

    button.csui_button.csui_green, button.csui_button.csui_green:active
    {
      background-color: #bf2cd6 !important; 
      border-color: #bf2cd6 !important;  
      border-radius: 6.25rem !important;

      &:active, &:hover
      {
        background-color: #ffffff !important; 
        color: #bf2cd6 !important; 
      }
    }

    .MuiInputBase-root
    {
      font-family: 'Cerebri Sans', sans-serif !important;
      font-weight: 700 !important;
    }

    > div
    {
      margin: 5px;
      height: 250px;
    }

    .public-chart-title
    {
      margin-top: 25px;
      text-align: left;
      height: fit-content;

      > div:first-child
      {

      }

      > div:last-child
      {
        font-weight: 700;
      }

      > div
      {
        font-family: 'Cerebri Sans', sans-serif !important;
      }
    }

    > .cta-image
    {
      img
      {
        height: 250px;
      }
    }

    .cta, .chart-container
    {
      border-radius: 4px;
    }

    > .cta
    {
      padding: 10px;
      //border: 1px solid lightgrey;
      min-width: 300px;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: black;

      > div
      {
        margin: 5px;
        text-align: left;

        div, h2
        {
          font-family: 'Cerebri Sans', sans-serif !important;
        }
      }

      > div,
      .MuiGrid-item
      {
        width: 100%;
      }

      #loading
      {
        margin: auto;
      }

      .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root
      {
        border-radius: 6.25rem;
      }
    }

    .charts-container {

      .chart-container {
        border: none;
        border-right: 1px solid lightgrey;
      }
    }

  }

  .star-icon
  {
    path
    {
      color: gold;
    }
    //display: flex;
  }

  #loading 
  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0px;
  }

  .img-holder 
  {
    width: 60px;
    height: 60px;
    img
    {
      max-width: 60px!important;
      max-height: 60px!important;
    }
  }

  .name-cell 
  {
    display: flex;
    //justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    > a
    {
      white-space: pre-wrap;      /* CSS3 */   
      white-space: -moz-pre-wrap; /* Firefox */   
      white-space: -o-pre-wrap;   /* Opera 7 */    
      word-wrap: break-word;      /* IE */
      word-break: break-word;
    }
  }

  .table-container
  {
    //overflow: auto;
    //min-height: 500px;

    display: flex;
    flex-direction: column;

    .MuiDataGrid-columnHeader
    {
      &:not(.MuiDataGrid-columnHeader--sorted)
      {
        .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer
        {
          display: none;
        }
      }
    }

    > div 
    {
      flex-grow: 1;
    }

    .button-holder
    {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      > button:nth-child(2)
      {
        margin-left: 10px;
      }
    }

    .grid-header
    {
      line-height: 20px;
      //height: 200px
    }

    .table-row
    {
      font-size: 1rem;/* 
      height: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important; */
      break-inside: avoid-page;
      /* break-before: always; */

      > .MuiDataGrid-cell
      {/* 
        height: 100px !important;
        min-height: 100px !important;
        max-height: 100px !important; */
        white-space: pre-wrap;      /* CSS3 */   
        white-space: -moz-pre-wrap; /* Firefox */   
        white-space: -o-pre-wrap;   /* Opera 7 */    
        word-wrap: break-word;      /* IE */
        word-break: break-word;
        line-height: 1.5rem !important;
      }
    }

/* 
    table.MuiTable-root:nth-child(2)
    {
      margin-bottom: 0px !important;
    }

    .CSUIDxReactGrid
    {
      > div > div > div > div:nth-child(2)
      {
        display: none;
      }

      .MuiTableCell-root {
        font-size: 1.15rem;
    }
    } */
  }

  .legend
  {
    > div
    {
      display: flex;
      align-items: center;

      > div:nth-child(1)
      {
        width: 19px;
        height: 19px;
      }
    }
  }

  #sponsored
  {
    .MuiPaper-root
    {
      //max-height: 500px;
      > div
      {
        > div
        {
          //max-height: 250px;
        }
      }
    }
  }

  #organic 
  {
    .MuiPaper-root
    {
      //max-height: 500px;
      > div
      {
        > div
        {
          //max-height: 500px;
        }
      }
    }
  }

  #search-box-container
  {

    &.public
    {

      button.csui_button.csui_green, button.csui_button.csui_green:active
      {
        background-color: #bf2cd6 !important; 
        border-color: #bf2cd6 !important;  
        border-radius: 6.25rem !important;
  
        &:active, &:hover
        {
          background-color: #ffffff !important; 
          color: #bf2cd6 !important; 
        }
      }
      
      .MuiInputBase-root
      {
        font-family: 'Cerebri Sans', sans-serif !important;
        font-weight: 700 !important;
      }

      button.csui_button.csui_button_disabled:not(.csui_button_outlined):after 
      {
        display: none !important;
      }

      .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root
      {
        border-radius: 6.25rem;
      }

      .search-row
      {
        @media screen and (max-width: 520px) 
        {
          flex-direction: column;

          > div 
          {
            width: 85%;
            max-width: 85%;

            > div, > button.csui_button
            {
              width: 100% !important;
            }
          }
        }
      }

    }

    .search-row-print
    {
      display: none;
    }

    .search-row
    {
      //margin: 10px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      //max-width: 600px;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    .search-row > div, .MuiInputBase-root
    {
      height: 35px;
    }

    .search-row > div
    {
      margin: 5px;
    }
  }
}

.sos-container {
  display: flex;
  flex-direction: column;
}

.inputs-container {
  display: flex;
}

.charts-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .chart-container {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    width: 350px;

    > div {
      padding: 10px;
    }

    > .header-div {
      border-bottom: 1px solid lightgrey;
    }

    > .chart-div {
      height: 250px;
      margin: auto;
    }

    ~ .chart-container {
      margin-left: 20px;
    }

    > .header-div {
      border-bottom: 1px solid lightgrey;

      > div:nth-child(1)
      {
        font-weight: 500;
      }

      > div:nth-child(2)
      {
        text-transform: uppercase;
      }
    }
  }
}


#digital-shelf-container.print-version
{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 45764375;
    padding: 20px;
    height: fit-content !important;
    box-shadow: none;
    background-color: white !important;

    .sos-logo
    {
      > div > span
      {
        font-size: 3rem !important;
      }
    }

    #search-box-container
    {
      .search-row-print
      {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .retailer
        {
          margin-right: 20px;
          padding-right: 20px;
          text-align: right;
          border-right: 1px solid black;

          div:nth-child(1)
          {
            font-weight: 700;
            font-size: 2rem;
          }
        }

        .terms
        {
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
      .search-row
      {
        display: none !important;
      }
    }

    .charts-container
    {
      .chart-container
      {
        width: 315px
      }

      .chart-container ~ .chart-container {
        margin-left: 8px;
      }
    }

    
  #organic 
  {
    display: none !important;
  }

  #organic-print
  {
    display: flex !important;
  }
}

.error-box
{
    display: block;
    padding:20px;
    margin-bottom: 20px;
    border: solid 1px #CCC;
    border-radius: 5px;
}
p.error
{
    color: red;
    font-weight: bold;
    margin-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
p.error::before
{
    content:"Error: "
}

p.input-error {
    transition: 0.5s;
    margin: 2px 0 0 4px;
    color: red;
    opacity: 0;
    height: 0;

    &.active {
        transition: 0.5s;
        opacity: 1;
        height: 10px;
    }
}

.error-box b
{
    color: #000;
    font-weight: bold;
}

.newjob_container_segment
{
    /* border: 1px solid #4FDA03 !important; */
    padding: 20px 40px !important;
    margin: 0 auto !important;
    /* max-width: 1950px !important; */
    width: 100%;
    margin-top: 20px !important;
    font-size: 1rem;
    min-height: 590px;

    .jobSummary
    {
        font-weight: 500;
    }

    .ui.circular.label.circle-label
    {
        margin-right: 10px !important;
    }

    .padLeft
    {
        padding-left: 12px;
    }

    .saveCancel {
        text-align: right;
    }

    .saveCancel button:not(:last-child) {
        margin-right: 12px !important;
    }

    .retailerButtons
    {
        //max-width: 955px;
        
        .csui_button
        {
            width: 180px;
            margin-right: 8px !important;
            margin-bottom: 8px !important;
        }
    }

    .ui.checkbox input:focus ~ label::before
    {
        border-color: indigo;
    }

    .ui.input input[type="text"]:not([readonly]),
    .ui.form textarea
    {
        &:focus
        {
            border-color: indigo;
        }
    }

    .loadingCircle
    {
        width: 100%;
        margin-top: 10%;
        text-align: center;
    }
}
@import "../../../../assets/scss/variables.scss";
.edit-preview-container {
    .product-title {
        display: flex;
        flex-direction: row;
        a {
            font-weight: 600;
            font-size: 16px;
            color: $text-color!important;
            text-decoration: underline;
        }
        .manufacturer {
            font-size: 13px;
            font-weight: 500;
        }
        .manufacturer::before {
            content:"By "
        }
        
    }
    .content-section {
        display: block;
        font-size: 13px;
        color: $text-color;
        margin-top: 40px;
        h1 {
            font-size: 14px!important;
            font-weight: 600!important;
            margin: 0px!important;
            padding: 0px!important;
        }
        .video-thumbnail {
            background: #FFF;
        }
        
    }
    .img-container {
        display: inline-block;
        width: 96px;
        height: 96px;
        border: solid 1px #CCC;
        border: solid 1px #CCC;
        border-radius: 5px;
        margin-right: 20px;
        text-align: center;
        img {
            max-width: 94px;
            max-height: 94px;
            border-radius: 5px;
        }
        &.thumbnail {
            width: 64px;
            height: 64px;
            margin-bottom: 20px;
            img {
                max-width: 62px;
                max-height: 62px;
                border-radius: 5px;
            }
        }
    }
    .bullets-list {
        li {
            padding-left: 20px;
        }
    }
}
.dynamicColumn
{
    margin-top: 20px;

    .editTextarea {
        line-height: 1.425em;
        font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
    }

    .editMDBCol
    {

        &.nonEditCol
        {
            > .editTextarea
            {
                visibility: hidden;
            }

            .nonEditValue
            {
                position: absolute;
                top: 0;
            }

        }

        > .editTextarea
        {
            border: 1px solid transparent;
            //border-bottom: 1px solid darkgrey;

            font-family: 'Montserrat', Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
            line-height: 1.4285em;

            &:focus
            {
                border: 1px solid indigo !important;
            }
        }

        > .md-form {
            margin: 0;

            
            > input.editInput
            {
                padding: 0;
                margin: 0;

                text-overflow: ellipsis;

                &:focus
                {
                    border-bottom-color: indigo !important;
                    box-shadow: none !important;
                }
            }
        }
    }

    .plus-circle
    {
        font-size: 1.2rem;
        margin-left: -7px;

        cursor: pointer;
        color: black;

        i
        {
            transform: rotateY(0deg) rotate(45deg);
        }
        
    }

    /* &.editColumn
    {
        .contentCard
        {
            // box-shadow: 0px 4px 9px 1px rgba(0,0,0,0.39);
        }
    } */

    .columnType
    {
        display: flex;
        align-items: center;
        padding-left: 15px;

        > div:first-child
        {
            font-weight: bold;
            margin-right: 20px;
        }

        .csui-select
        {
            width: 170px;
        }
        
    }

    .card-body
    {
        // margin-top: 10px;

        .titleRow
        {
            height: 60px;

            .dropdownWrapper
            {
                display: flex;
                flex-direction: column;
            }
        }

        .restBody
        {
            //margin-top: 30px;

            .whiteBox
            {
                padding: 10px;
                border: 1px solid rgb(228, 228, 228) !important;
                border-radius: 3px;
            }

            .stats
            {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > div
                {
                    background-color: white;
                    height: 120px;
                    width: 140px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 30px;
                    //justify-content: center;
                    text-align: center;

                    .score
                    {
                        color: indigo;
                        font-size: 2.2rem;
                        font-weight: 900;
                        margin-bottom: 6px;
                    }

                    .statusName
                    {
                        text-transform: uppercase;
                        font-weight: 300;

                        span
                        {
                            font-weight: 900;
                        }
                    }

                    .versus
                    {
                        margin-top: -6px
                    }
                }
            }

            .collapseContainer
            {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                .cardTitle
                {
                    .icons
                    {
                        visibility: hidden;
                    }
                }

                > div
                {
                    background-color: white;
                    min-height: 30px;

                    ~ div
                    {
                        margin-top: 10px;
                    }
                }

                .scoreDisplay
                {
                    border-bottom: 1px solid black;
                    margin-bottom: 10px;
                    padding-bottom: 10px;

                    /* .scoreTitle
                    {
    
                    } */
    
                    .scoreValue
                    {
                        font-size: 2rem;
                        font-weight: 900;
                        color: indigo;
                    }
    
                    .scoreDetail
                    {
                        font-weight: 700;
                    }

                    .scoreBarWrapper
                    {
                        margin-top: 16px;
                    }
                }

                .UID-data
                {
                    margin-bottom: 20px;

                    height: 140px;
                    overflow-y: auto;
                    overflow-x: hidden;

                    textarea.editTextarea
                    {
                        height: 22px !important;
                    }
                }

                .contentCard
                {
                    display: flex;
                    flex-direction: column;

                    margin-top: 10px;
                    border: 1px solid rgb(228, 228, 228) !important;
                    border-radius: 3px;
                    padding: 10px;

                    /* .value
                    {
                        //margin-bottom: 16px;
                    } */

                    textarea
                    {
                        font-family: 'Montserrat';
                        line-height: 19.999px;
                        color: rgb(102,102,102);
                        height: 90px !important;
                    }

                    .Marketing-Bullets-data .editDiv textarea,
                    .editDiv textarea
                    {
                        height: 30px !important;
                    }

                    .Marketing-Bullets-data textarea
                    {
                        height: 50px !important;
                    }
                }

                .editDiv
                {
                    display: flex;
                    align-items: center;
                    padding: 5px 3px;
                    margin: 4px 0;
                    border-top: 1px solid lightgrey;
                    border-bottom: 1px solid lightgrey;
                }

                .Marketing-Bullets-data
                {
                    .editDiv
                    {
                        border-bottom: 1px solid black;
                        margin-bottom: 10px;
                    }
                }

                

                .Assets-Images-data
                {
                    display: flex;
                    flex-wrap: wrap;

                    /* > div
                    {
                        
                    } */

                    .product-image
                    {
                        width: 50px;
                        height: 50px;
                    }
                }

                .Sentiment-data
                {

                    .sentimentBreakdown
                    {
                        margin: 20px 0;

                        .breakdownRow
                        {
                            display: flex;
                            align-items: center;

                            .number
                            {
                                width: 15px;
                                margin-right: 12px;
                                font-weight: bold;
                            }
                            .star
                            {
                                width: 20px;
                                margin-right: 10px;
                            }
                            .bar
                            {
                                width: 100%;

                                .progress-bar
                                {
                                    background-color: indigo;
                                }
                            }
                            .percent
                            {
                                width: 50px;
                                margin-left: 16px;
                                font-weight: bold;
                            }
                        }
                    }
                }
                
            }
        }
    }

    .groupClass_Marketing-Description-editTextarea
    {
        height: 350px !important;
        overflow-y: auto;
    }
}
.date-row {
    display: flex;
    flex-direction: row;
}
.date-selector {
    padding-left: 10px!important;
    img {
        width: 12px!important;
        height: 12px!important;
    }
}
.date-container {
    display: inline-block;
    padding-top: 2px!important;
}
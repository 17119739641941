.catalog-tags-dropdown.catalog-filters
{
    ul
    {
        list-style: disc;

        li {
            display: list-item;
            margin-bottom: 0px;
            margin-top: unset;
            margin-left: 15px;
            color: unset;
            font-size: unset;
            cursor: unset;
        }
    } 
}
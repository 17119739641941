.UserAccountSettings 
{
  .md-form input:not([type]):focus:not([readonly]), 
  .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), 
  .md-form input[type="search-md"]:focus:not([readonly]), 
  .md-form textarea.md-textarea:focus:not([readonly]) 
  {

    border-bottom: 1px solid indigo;
    box-shadow: 0 1px 0 0 indigo;
  }

  .md-form input:not([type]):focus:not([readonly]) + label, 
  .md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="password"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="email"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="number"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="search"]:not(.browser-default):focus:not([readonly]) + label, 
  .md-form input[type="search-md"]:focus:not([readonly]) + label, 
  .md-form textarea.md-textarea:focus:not([readonly]) + label,
  ul.dropdown-content.select-dropdown.fadeElement.fadeIn + label.text-primary
  {
    color: indigo !important;
  }

  ul.dropdown-content li > span
  {
    color: #666666 !important;
  }

  ul.dropdown-content li.active > span:hover,
  .dropdown-content li > span:hover
  {
    background-color: indigo !important;
    color: white !important;
  }

  ul.dropdown-content li.active > span
  {
    color: indigo !important;
  }
  
  ul.dropdown-content
  {
    max-height: 20rem;
  }

  .mdb-main-label.active.text-primary
  {
    color: #757575 !important;
  }

  .row {
    margin-bottom: 20px;
  }
}

main .profile-settings
{
  margin-bottom: 50px;
  padding: 0px !important;
  max-width: 100%;
  width: 100%;
}

#profile-v2 .card.card-cascade .view.view-cascade {
  padding: 1.6rem 1rem;
  text-align: center;
}
.profile-settings .tab-content {
  border-left: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  padding: 30px;
  padding-bottom: 50px;

}
.profile-settings .account {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
}
h1.custom {
  color: indigo;
  font-weight: 300;
  font-size: 18px;
  text-transform: uppercase;
  padding-top: 40px;
}
h1.custom span {
  font-weight: 900;
}
